import React, { Component } from 'react'
import "./contacto.scss"
import { FormContainer, checkableBoolProps, setInputProps } from '../common/forms/Form'
import { SellPropertyValidationSchema } from '../common/forms/constraints/ValidatonSchemas'
import Swal from 'sweetalert2'
import $ from "jquery"
import { MailService } from '../common/Http/MailService'
import { propertySale } from '../templates/email/PropertySale'
import Data from '../data/data'
export default class Vendetuterreno extends Component {
    constructor() {
        super()
        this.mailService = new MailService()
        this.submitForm = this.submitForm.bind(this)
        this.handleFile = this.handleFile.bind(this)
    }

    state = {
        file: {}
    }
    componentDidMount(){
        $(".link").removeClass("active")
        $("#vende").addClass("active");
    }

    async submitForm(values, { setSubmitting, resetForm }) {
        await this.mailService.from("Casaideal <no-reply@casaideal.com.pe>")
            .to("novoac@casaideal.com.pe")
            .subject("Venta de terreno")
            .html(propertySale(values))
            .attachment(this.state.file)
            .send()
            .then(r => {
                setSubmitting(false)
                Swal.fire({
                    title: '',
                    text: 'Mensaje enviado',
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'red'
                })
            }).catch(e =>{
                setSubmitting(false)
                Swal.fire({
                    title: '',
                    text: '¡Error! Intentelo más tarde',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'red'
                })
            })
    }
    initialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        id_type: "",
        id_number: "",
        sell_as: "",
        terrain_area: "",
        district: "",
        address: "",
        currency: "",
        terrain_value: "",
        message: "",
        terms: false
    }

    handleFile(e) {
        this.setState({file: e.target.files[0]})
    }

    render() {
        return (
            <Data.Consumer>
            {context =>(
            <FormContainer initialValues={this.initialValues} validationSchema={SellPropertyValidationSchema} onSubmit={this.submitForm}>
                {form => {const {handleSubmit } = form;
                return(
                <div className={"vende-tu-terreno "+this.props.page}>
                    <h2 className="title"><span className='bold'>VENDE</span>  TU TERRENO</h2>
                    <p className="paragraph">Llena el formulario y nos contactaremos contigo</p>
                    <form className="formulario" onSubmit={handleSubmit}>
                        <input type="text" placeholder="Nombre" {...setInputProps("fname", "input col", form)}></input>
                        <input type="text" placeholder="Apellidos" {...setInputProps("lname", "input", form)}></input>
                        <input type="tel" placeholder="Teléfono" {...setInputProps("telephone", "input col", form)}></input>
                        <input type="email" placeholder="E-mail" {...setInputProps("email", "input", form)}></input>
                        <select {...setInputProps("id_type", "input col", form)}>
                            <option selected disabled>Tipo de documento </option>
                            <option value="DNI">DNI</option>
                            <option value="Pasaporte">Pasaporte</option>
                        </select>
                        <input type="text" placeholder="N° de documento" {...setInputProps("id_number", "input", form)}></input>
                        {/* <label className="sub-title" >VENDO EL TERRENO COMO:</label> */}

                        <select {...setInputProps("sell_as", "input col", form)}>
                            <option selected disabled>Vendo terreno como</option>
                            <option value="Persona natural">Persona natural</option>
                            <option value="Persona jurídica">Persona jurídica</option>
                        </select>
                        <input type="text" placeholder="Superficie en M²" {...setInputProps("terrain_area", "input", form)}></input>
                        <input type="text" placeholder="Distrito" {...setInputProps("district", "input col", form)}></input>
                        <input type="text" placeholder="Dirección" {...setInputProps("address", "input ", form)}></input>
                    
                        <select {...setInputProps("currency", "input col", form)}>
                            <option selected disabled>Tipo de moneda</option>
                            <option value="S/">S/</option>
                            <option value="$">$</option>
                        </select>
                        <input type="text" placeholder="Ingrese el valor del terreno" {...setInputProps("terrain_value", "input ", form)}></input>
                        <div className="textarea col">
                            <label for="message" className="mensaje">Mensaje*</label>
                            <textarea id="message" rows="5" placeholder="Escribenos tu mensaje aquí." {...setInputProps("message", "form-control", form)}></textarea>
                        </div>
                        <div className="file">
                            <div className="button-wrap"> 
                                <label className="new-button" for="upload"> Adjuntar archivos</label>
                                <input id="upload" type="file" onChange={this.handleFile}></input>
                                <p id="archivo">{this.state.file.name || "Ningún archivo"}</p>
                            </div>
                            <div className="flex-row atributos">
                                <p id="tipo-archivo">Archivos en: doc, docx, jpg, jpeg, pdf, png. Máximo 6MB</p>
                            </div>
                        </div>
                        <div className="flex-column button-radius">
                            <div className="radio flex-row">
                                <label className="button-title flex-row" for="terminos">
                                <input className="boton-radio" type="checkbox" id="terminos" {...checkableBoolProps("terms", "boton-radio", form)}></input>
                                <div className="radius" id="link-terminos"></div> Estoy de acuerdo con las <a href="/politicas-de-privacidad" target="_blank" className="d-block terminos"> políticas de privacidad</a></label><br></br>
                            </div>
                        </div>
                <div className="btn"><button type="submit" className="btn-submit" disabled={form.isSubmitting}>{form.isSubmitting ? "Enviando" : "Enviar"}</button></div>
                    </form>
                </div>)}}
            </FormContainer>
            )}
            </Data.Consumer>
        )
    }
}
