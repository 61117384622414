import { useEffect, useState } from 'react';
import './modalproject.scss'
import { HOST } from '../../data/config';
export const ModalProject = () => {
    const [showModal, setShowModal] = useState(false);
    const [image, setImage] = useState('');
    const getModal = async() => {
        try {
            const response = await fetch(`${HOST}/wp-json/custom-api/v1/popup`).then(response => response.json());
            setShowModal(response.modal_active)
            setImage(response.imagen.url)
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        getModal();
    }, []);
    if(showModal){
        return (
            <div className='content-modal'>
                <div className='modal'>
                    <div className='icon icon-close mask' onClick={()=>{setShowModal(false)}}>
    
                    </div>
                    <img className='' src={image} ></img>
                </div>
            </div>
        )
    }
    return(
        <></>
    )

}