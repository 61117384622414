import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './thanks.scss'
export default function Thanks(props){
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div className='thanks'>
            <img className='tramado-1 desktop' src={require('../../Assets/iconos/thanks-icon-left-top.svg').default} ></img>
            <img className='tramado-2 desktop' src={require('../../Assets/iconos/thanks-icon-bottom-right.svg').default} ></img>
            <img className='tramado-3' src={require('../../Assets/iconos/bottom-icon.svg').default} ></img>
            <div className='container'>
                <img className='message-icon' src={require('../../Assets/iconos/message.svg').default} ></img>
                <span className='title bold'>Envío exitoso</span>
                <p className='paragraph'>Gracias por comunicarte con nosotros. Nos pondremos en contacto a la brevedad posible</p>
                <Link to="/" className='button'>
                    Ir a Inicio
                </Link>
            </div>
        </div>
    )
}