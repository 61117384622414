import React, { Component, useEffect, useState } from 'react';
import './proyectos.scss';
import $ from 'jquery';
// import inicio from '../../data/projects/pageInicio'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { HOST } from '../../data/config';
import Skeleton from 'react-loading-skeleton';
class Proyectos extends Component {
	constructor(props) {
		super(props);
		this.obtenerDistritosUnicos = this.obtenerDistritosUnicos.bind(this);
		this.state = {
			projects: this.props.value.projects,
			projectsFilter: this.props.value.projects,
			districts: [],
		};
		this.changeDistric = this.changeDistric.bind(this);
	}

	setStateFromInput = ({ target }) => this.setState({ [target.name]: target.value });
	changeDistric(e) {
		let items = this.state.projects;
		if(e.target.value == 'all') {
			this.setState({ projectsFilter: items });
		}else{

			let newFiltered = items.filter(item => {
				return item.district_project == e.target.value;
			});
			this.setState({ projectsFilter: newFiltered });
		}
	}
	obtenerDistritosUnicos(proyectos) {
		const distritos = proyectos.map(proyecto => proyecto.district_project); // Extrae los nombres de los distritos
		return [...new Set(distritos)]; // Crea un conjunto único y lo convierte de nuevo en un array
	}
	async fetching(page) {
		const data = await fetch(`${HOST}/wp-json/custom-api/v1/projects`);
		const projects = await data.json();
		return projects.results;
	}
	async componentDidMount() {
		let data = (await this.fetching()) || false;
		if (data) {
			this.setState({ projects: data, projectsFilter: data, districts: this.obtenerDistritosUnicos(data) });
		}
	}
	render() {
		return (
			<div className='Proyectos'>
				<img class='tramado-1 desktop' src={require('../../Assets/images/iconos/tramado-1.png')}></img>
				<img class='tramado-2 desktop' src={require('../../Assets/images/iconos/tramado-2.png')}></img>
				<img class='tramado-3 desktop' src={require('../../Assets/images/iconos/tramado-3.png')}></img>
				<link href='https://fonts.googleapis.com/css?family=Bree+Serif' rel='stylesheet' type='text/css'></link>

				<div className='Content_proyectos' id='Content_proyectos'>
					<div className='selectores'>
						<h2 className='title' id='all-proyectos'>
							Nuestros Proyectos
						</h2>
						<div className='content-select'>
							<span className='title-select'>Distrito</span>
							<select
								className='distritos'
								onChange={e => {
									this.changeDistric(e);
								}}
							>
								<option value='all' selected>
									Seleccionar
								</option>
								{
									this.state.districts.map((distrito, index) => {
										return (
											<option key={index} value={distrito}>
												{distrito}
											</option>
										)
									})
								}
							</select>
						</div>
					</div>
					<div className='builds'>
						{this.state.projectsFilter.map((project, index) => {
                            if(project.show_page == false) return;
							return (
								<div className={`items img1 ${project.class} ${project.id}`} key={index}>
									<div className='build '>
										<ImageRender url={project?.fachada?.url}></ImageRender>
										<div className='info-build'>
											<div className='img_info_build' style={{ backgroundImage: `url(${project?.logo?.url})` }}></div>
											<div className='type-proyect'>{project.status}</div>
											<div className='paragraph'>
												<span className='title d-block'>{project?.district_project}</span>
												<span className='attributes d-block bold'>{project.dorms}</span>
												<div className='separate'></div>
												<p className='p-none heavy'>{project.desde}</p>
												<p className='p-none'>{project.hasta || project.address}</p>
												<div className='content-icon'>
													<div className='rule mask'></div>
													<span className='meters'>{project.metraje}</span>
												</div>
											</div>
											<Link to={project.project_name} className='button-look'>
												Ver proyeccto
											</Link>
										</div>
										<div className=''>
											<img className='logo' src={project.bono}></img>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}
const MapStateProps = state => {
	return {
		value: state.projectsStore,
	};
};
export default connect(MapStateProps, null)(Proyectos);

function ImageRender({ url }) {
	const [imgLoaded, setImgLoaded] = useState(false);
	const loadImage = () => {
		const image = new Image();
		image.src = url;
		image.onload = () => {
			setImgLoaded(true);
		};
	};

	// Cargar la imagen cuando se monta el componente
	useEffect(() => {
		loadImage();
	}, [url]);

	return <div className='img-build'>{imgLoaded ? <img src={url} /> : <Skeleton style={{ width: '100%', height: '100%' }} />}</div>;
}
