import React, { Component } from 'react'
import Proyectos from '../proyectos/proyectos'
import "./proyectos.scss"
export default class Proyectosenventa extends Component {
    componentDidMount(){
        window.scrollTo(0,0)
        document.querySelector("#all-proyectos").textContent = "Proyectos en venta"
    }
    render() {
        return (
            <div className="proyectos-en-venta">
                <Proyectos></Proyectos>
            </div>
        )
    }
}
