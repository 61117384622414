const unity = {
    sperant_id: 20,
    route:"/unity",
    page:"unity",
    district:'Santa Beatriz',
    whatsapp:["924898307"],
    titlehead:"Proyecto Unity | Departamentos en Santa Beatriz",
    metadescription:"Vive en la zona más céntrica de Santa Beatriz, límite con Jesús María. Departamentos de 1, 2 y 3 dorms desde S/351,300. Unity es un proyecto que crece contigo para poder disfrutar de cada espacio junto a tu familia o amigos, cuenta con áreas que te dan esa libertad que tanto necesitas Cotiza aquí.",
    metakeywords:"departamentos en Santa Beatriz, departamentos en venta en Santa Beatriz, inmobiliaria, Santa Beatriz, casaideal inmobiliaria, departamentos límite con jesús maría, departamentos de 1, 2 y 3 dormitorios, edificio con entrega inmediata, departamentos con entrega inmediata",
    iziPubKey: {
        prod: "71379667:publickey_u4bHfowsjySebqURqXAwsGWY1LXh13LLF3Hb1RWzWjOgp",
        dev: "71379667:testpublickey_cIj2hVIYdsZo3qjyI2Cjg0pn4CBDVBHfFO8NDqiN0PTkG",
    },
    areas:{
        cant:4,
        comunes:[
        ],
        interiores:[
        ],
        exteriores:{
            cantButton:"avance-none",
            title:"",
            desktop:[
            ]
        },
        recorrido:{
            cantButton:"avance-none",
            title:"",
            desktop:[

            ]
        },
        avance:{
            cantButton:"avance-none",
            title:"Avance de obra Septiembre",
            desktop:[
            ],
        },
        end_project:{
            cantButton:5,
            title:"",
            images:[
            ]
        },
        depa_piloto:[
            
        ],
        recorrido:"https://kuula.co/share/collection/7Pym4?fs=1&vr=1&sd=1&initload=0&thumbs=3&inst=es&info=0&logo=-1",
    },
    form:[
        {value:1,title:"1 Dormitorio"},
        {value:2,title:"2 Dormitorios"},
        {value:3,title:"3 Dormitorios"}
    ],
    info:{
        title:"UNA VIDA EN EL",subtitle:"LUGAR CORRECTO",
        entrega:'Entrega inmediata',
        entregamovil:'Entrega inmediata',
        frase:'LA ZONA MÁS CÉNTRICA DE LIMA LÍMITE </br> CON <span class="bold">JESÚS MARÍA</span>',
        pisos:"17",depas:"96",dorms:"1, 2 y 3 Dorms.",ubicacion:"Av. Arenales N° 1109, Cercado de Lima",precio:" S/401,200",desde:"40.02m²",hasta:"106.26m²",
        fachada:require("../../Assets/images/fachada/Slide/UNITY.jpg"),
        fachadaMinified:require("../../Assets/images/fachada/Slide/resize/resize-UNITY.jpg"),
        // ingreso:require("../../Assets/images/fachada/unity/acercamiento-unity.jpg"),
        parrafo:'<p class="paragraph">Un proyecto que crece contigo, porque entendemos que hoy por hoy, lo más importante es encontrar un lugar correcto para vivir.</p><p class="paragraph paragraph-2">Unity tiene como principal objetivo; pasar en familia, aprovechar al máximo el tiempo y sobre todo disfrutar de la comodidad y libertad que le da cada espacio. Nuestro proyecto cuenta con la mejor ubicación, para ti y para los que te acompañan. </p>',
        parrafoArquitectura1:'En Unity podrás disfrutar de cada espacio junto a tu familia o amigos, porque contamos con áreas que te dan esa libertad que tanto necesitas. ¿Qué esperas para formar parte de Unity?',
        brochure:'https://casaideal.com.pe/unity/brochure-unity.html',
        brochure_mobile:'https://casaideal.com.pe/unity/mobile/brochure-unity.html',
        arquitectura:'<span class="bold">ARQUITECTURA </span>Y DISEÑO',
        mapa:require("../../Assets/mapas/unity-map.jpg"),
        class:"",
        sitios:[
            {title:"Clínicas - Hospitales",sitio:["Policlínico Jesús María","INCOR","Hospital Edgardo Rebagliati","ESSALUD","Clínica Santa Beatriz","Policlínico Pablo Bermúdez","Clínica de Fertilidad"]},
            {title:"Bancos",sitio:["Cajero BCP","Scotiabank"],},
            {title:"Entidades",sitio:["Serpost","SUTRAN","MINSA","MININTER"]},
            {title:"Colegios, institutos",sitio:["Instituto SISE","Instituto Italiano de Cultura","Trilce"]},
            {title:"Teatros, museos, parques",sitio:["Museo de Historia Natural UNMSM","Teatro Pirandello","Circuito Mágico del Agua","Parque Habich","Jardín Botánico de Plantas Medicinales","Estadio Nacional","Campo de Marte"]},
            {title:"Metropolitano",sitio:["Estación México"]},
        ],
    },
    slideDepa:[
        

    ],
    atributos:[
        {icon:"camara",text:"<strong>Cámaras de seguridad</strong></br> en áreas comunes"},
        {icon:"sala-de-espera",text:"<strong>Lobby &</br> sala de espera</strong>"},
        {icon:"relax",text:"<strong>Relax zone</strong>"},
        {icon:"terraza",text:"<strong>Terraza Grill</strong>"},
        {icon:"yoga",text:"<strong> Yoga y bienestar</strong>"},
        {icon:"sala-coworking",text:"<strong>Coworking</strong>"},
        {icon:"area-chill",text:"<strong>Área chill</strong>"},
        {icon:"lavado",text:"<strong>Zona </br> de lavado</strong>"},
        {icon:"gas",text:`<span class="text" style="text-wrap: balance;display:flex;flex-direction: column;">Redes internas de Gas Natural <br><span style="font-weight: 100;font-size: .7rem;opacity: .8;">*Sujeto a conexión de Cálidda</span></span>`},
    ],
    tipologias:{
        page:"unity",
        template_id: 92,
        buttons:[{text:"01",value:1},{text:"02",value:2},{text:"03",value:3},{text:"Todos",value:"all"}],
        brand:require('../../Assets/images/tipologias/unity/logo.png'),
        cards: [
 ],
    }
}

export default unity