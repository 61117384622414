import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    numbers:[1,2,3],
    projects:[
        {
            id: "Print",
            url: "/print",
        },
        {
            id: "Stay",img: require("../../Assets/images/proyectos/renders/render_stay.png"),
            url: "/stay",
        },
        {
            id: "Midway",
            url: "/midway",
        },
        {
            id: "Unity",
            url: "/unity",
        },
        {
            id: "Unbox",
            url: "/unbox",
        },
    ],
    form:[{value:1,title:"1 Dorm"},{value:2,title:"2 Dorms"},{value:3,title:"3 Dorms"}]
}
export const projectsStore = createSlice({
    name:'projectsStore',
    initialState,  
    reducers:{
        updateProjects:(state,action)=>{
            state.projects = action.payload
        }
    }
})

export default projectsStore.reducer
