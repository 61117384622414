import React, { Component } from 'react';
import Contacto from '../components/contacto/contacto';
import Information from '../components/info/information';
import Sliderdeimagenes from '../components/sliderImagenes/sliderdepartamento';
import './template.scss';
import Areas from '../components/areas/areas';
import Mapa from '../components/mapa/mapa';
import Slider from 'react-slick';
import $, { data } from 'jquery';
import {Flotante} from '../components/flotante/flotante';
import MediaQuery from 'react-responsive'; 
import { ref } from 'yup';
import Filtro from '../components/filtro/filtro';
import Calculator from '../components/calculadora/calculator';
import Data from '../components/data/data';
import QuoterModal from '../components/quoter/quoter';
import { SperantService } from '../components/common/Http/SperantService';
import { connect } from 'react-redux';
import { updateSelected } from '../data/calculator';
import { ProjectHandler } from './store/ProjectHandler';
import { Helmet } from 'react-helmet';
import { ModalProject } from '../components/modalproject/modalprojects';
import { HOST } from '../data/config';
import { configSlider } from './config';
import { Typologies } from './components/tipologies';
import { SectionTypologies } from './components/sectionTypologies/sectionTypologies';
import { ShapeSquare } from '../components/UI/shapeSquare';

class Templates extends Component {
	constructor(props) {
		super(props);
		this.sperantService = new SperantService();
		this.projectHandler = new ProjectHandler();
		this.skip = this.skip.bind(this);
		this.showQuoter = this.showQuoter.bind(this);
		this.closeQuoter = this.closeQuoter.bind(this);
		this.state = {
			visible: false,
			allCards: [],
			cards: [],
			selectCard: {},
			numDorms: ['all'],
			vista: ['all'],
			type_id: '',
			price_from: '',
			data: {
				project_name: '',
				imagen: false,
				slider_avance: [],
				banner: [],
				address: '',
				slider_exteriors: [],
                price_from: "",
                metraje: "",
                dorms: "",
				brochure: "",
				url_3d: '',
				slider_avance: [],
				slider_end_project: [],
				slider_areas_comunes: [],
				slider_interiores: [],
				typologies: [],
				slider_end_project: [],
				brochure_mobile: "",
				sliderslider_depa_piloto: [],
				attributes: [],
				phrase: "",
				description: "",
				render_design: {
					url: '',
				},
				description_design: '',
				image_map: {
					url: '',
				},
				near_places: [],
				message_wpp: '',
			},
			settings: configSlider,
		};
	}
	componentWillUpdate(prevProps) {
		
	}
	async fetching(page) {
		const data = await fetch(`${HOST}/wp-json/custom-api/v1/project?name=${page}`);
		const projects = await data.json();
		return projects;
	}
	async componentDidMount() {

        window.scrollTo(0, 0);

		let data = (await this.fetching(this.props.data.page)) || false;
		
		if (data) {
			const project = await this.projectHandler.formatProject(this.props.data);
			this.setState({ data: data });
		}
		
	}
	showQuoter(type) {
        this.setState({ type_id: type.id, visible: true });
	}

	closeQuoter() {
		this.setState({ visible: false });
	}
	skip(e) {
		e.target.style = 'display:none';
		document.querySelector('.paragraph-2').classList.add('active');
	}
	updateSelectUnit = item => {
		this.props.updateSelectUnit(item);
	};
	render() {
		return (
			<Data.Consumer>
				{context => (
					<main className={`template ${this.props.data.page}`}>
						<Helmet>
							<title>{this.props.data?.titlehead || ''}</title>
							<meta name='description' content={this.props.data?.metadescription || ''}></meta>
							<meta name='keywords' content={this.props.data?.metakeywords || ''}></meta>
						</Helmet>
						<Calculator page={this.props.data.page} url={this.props.data.whatsapp}></Calculator>

						<Flotante project={this.props.data.page} message={this.state.data.message_wpp}></Flotante>
						<Sliderdeimagenes img={this.state.data.banner} />
						<section id='contacto' className='information container'>
							<div className='info'>
								<span className='handing-over bold'>{this.state.data.status}</span>
								{this.props.data.page == 'nou' ? (
									<span className='handing-over separe'>
										<span className='spacing'>ÚLTIMO DEPA DESDE</span>
										<span className='bold'> {this.state.data.price_from}</span>
									</span>
								) : (
									<span className='handing-over separe'>
										<span className='spacing'>DEPAS DESDE </span>
										<span className='bold'> {this.state.data.price_from}</span>
									</span>
								)}
								<span className='title' dangerouslySetInnerHTML={{ __html: this.state.data.phrase }}></span>
								{
									this.state?.data?.description?.length > 0 ? <div className='content-paragraph' dangerouslySetInnerHTML={{ __html: this.state.data.description }}></div> : 
									<div className='content-paragraph'> 
										<ShapeSquare />
										<ShapeSquare />
										<ShapeSquare />
										<ShapeSquare />
									</div> 
								}
								
								<p className='paragraph place'>
									<div className={`mask icon-place `}></div>
									{
										this.state.data.address.length > 0 ?
									<span dangerouslySetInnerHTML={{ __html: this.state.data.address }}></span> : 
									<ShapeSquare />
									}
								</p>
								{this.props.data.page == 'nou' ? (
									<p className='paragraph place'>
										<div className='mask rule'></div>
										{this.state.data.metraje.length > 0 ? this.state.data.metraje : <ShapeSquare />}
									</p>
								) : (
									<p className='paragraph place'>
										<div className='mask rule'></div>
										{this.state.data.metraje.length > 0 ? this.state.data.metraje : <ShapeSquare />}
									</p>
								)}
								<p className='paragraph place'>
									<div className='mask dorm'></div>
									{this.state.data.dorms.length > 0 ? this.state.data.dorms : <ShapeSquare />}
								</p>
								{	
									window.innerWidth > 500 && this.state.data.brochure ? (
										<a href={`/${this.state.data.project_name.toLocaleLowerCase()}/brochure-${this.state.data.project_name.toLocaleLowerCase()}.html`} target='_blank' className='btn-radio outline'>
											Ver brochure
										</a>
									) : null
								}
								{	
									window.innerWidth <= 500 && this.state.data.brochure_mobile ? (
										<a href={`/${this.state.data.project_name.toLocaleLowerCase()}/mobile/brochure-${this.state.data.project_name.toLocaleLowerCase()}.html`} target='_blank' className='btn-radio outline'>
											Ver brochure
										</a>
									) : null
								}
							</div>
							<div className='form' id='formulario'>
								<Contacto project={this.props.data} form={this.props.data.form}></Contacto>
							</div>
						</section>
						<section className='section-atributos'>
							<img className='background-black' src={require('../Assets/images/iconos/background-black.png')}></img>
							<h2 className='title'>
								<span className='bold'>CARACTERÍSTICAS</span> DEL PROYECTO
							</h2>
							<div className='content-atributos'>
								{this.state.data.attributes && this.state.data.attributes.map((item, index) => {
									return (
										<div key={'attr' + index} className='item'>
											{
												item.icono.url.includes('svg') ? (
													<div className={'mask '} style={{ WebkitMaskImage: `url(${item.icono.url})` }}></div>
												) : (
													<div className='mask' style={{backgroundColor:'transparent', backgroundImage: `url(${item.icono.url})`,width: '6rem'}}></div>
												)
											}
											
											<span className='text' dangerouslySetInnerHTML={{ __html: item.title }}></span>
										</div>
									);
								})}
							</div>
						</section>
						<section className='description'>
							<div className='container'>{
									this.state.data.render_design?.url ? (
										<img className='render' src={this.state.data.render_design?.url}></img>
									): <ShapeSquare className='square-render_design'></ShapeSquare>
								}
								{/* <img className="fachada" src={this.props.data.info.ingreso}></img> */}
								<div className='content-info'>
									<h2 className='title' dangerouslySetInnerHTML={{ __html: 'ARQUITECTURA & DISEÑO'}}></h2>
									{
										this.state.data.description_design ? (
											<p className='paragraph' dangerouslySetInnerHTML={{ __html: this.state.data.description_design }}></p>
										) : (
											<>
												<ShapeSquare className='square_description_design'/>
												<ShapeSquare className='square_description_design'/>
											</>
										)
									}
								</div>
							</div>
						</section>
						<Areas areas={this.props.data.areas} page={this.props.data.page} data={this.state.data}></Areas>
						<SectionTypologies 
                            typologies={this.state.data.typologies}
                            showQuoter={this.showQuoter}
                            openModal={context.openCalculator}
                            page={this.props.data.page}
                            ></SectionTypologies>
						<Mapa image_map={this.state.data.image_map} places={this.state.data.near_places} page={this.props.data.page}></Mapa>
						<QuoterModal visible={this.state.visible} actionModalCancel={this.closeQuoter} type_id={this.state.type_id} page={this.props.data.page} template_id={this.props.data.tipologias.template_id} sperant_id={this.props.data.sperant_id} />
					</main>
				)}
			</Data.Consumer>
		);
	}
}
export default connect(null, { updateSelected })(Templates);
