import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple'
import React, { Component, useEffect, useState } from 'react'
import './flotante.scss'
export const Flotante = ({message, project =''})=> { 
    const [data, setData] = useState({ message: message, project: project })
    
    useEffect(() => {
        
    }, [])
   
    const moveTo= (elementFrom,elementTo)=>{
        let navHeight = document.querySelector("#nav").clientHeight
        window.scrollTo(offset(elementFrom).top,offset(elementTo).top-(navHeight+30))
    }
    const offset =(e)=>{
        let element = document.querySelector(e)
        if(element){
            try { 
                let top = element.getBoundingClientRect().top + window.scrollY 
                let left = element.getBoundingClientRect().left
                return {top:top,left: left}
            }catch(err){
                return "dosnt exist"
            }
        }else{
        }
    }
    return (<div className="content-button-contact">
            {project && <div id="contact" className="whatsapp d-block" onClick={()=>moveTo("#contact","#formulario")}>
                                <img className='contact' src={require('../../Assets/iconos/contact.svg').default}></img>
                            </div>}
                            <a target="_blank" href={`${message}`} className="whatsapp d-block">
                                <img className='' src={require('../../Assets/images/inicio/WhatsApp_Logo_1.png')}></img>
                            </a>
                        </div>
    )
}