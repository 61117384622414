const town = {
        sperant_id: 17,
        route:"/town",
        page:"town",
        district:'Santa Beatriz',
        whatsapp:["924875872"],
        titlehead: "",
        metakeywords:"",
        metadescription:"",
        iziPubKey: {
            prod: "96148066:publickey_iplFETV8s3JmHJnza7OltybPohvhT3dqpEMQtcukbZyje",
            dev: "96148066:testpublickey_6QLVkuXUVDR9D2pvPgS8cUt7ucaVrh1gV5hHSfFLWmRWZ",
        },
        areas:{
            cant:4,
            comunes:[
                {title:"LOBBY",icon:"lobby",desktop:require("../../Assets/images/Slider/town/comunes/desktop/lobby.jpg"),movil:require("../../Assets/images/Slider/town/comunes/desktop/lobby.jpg"),paragraph:"El diseño transmite vida y estilo desde el ingreso."},
                {title:"SUM",icon:"relax",desktop:require("../../Assets/images/Slider/town/comunes/desktop/SUM.jpg"),movil:require("../../Assets/images/Slider/town/comunes/desktop/SUM.jpg"),paragraph:"La gran amplitud y funcionalidad de este espacio permite la celebración de todo tipo."},
                {title:"JARDÍN ZEN",icon:"jardin",desktop:require("../../Assets/images/Slider/town/comunes/desktop/Jardin.jpg"),movil:require("../../Assets/images/Slider/town/comunes/desktop/Jardin.jpg"),paragraph:"Desconéctate de un día agitado sin salir de casa."},
                {title:"PARRILLA GOURMET",icon:"parrilla",desktop:require("../../Assets/images/Slider/town/comunes/desktop/parrilla.jpg"),movil:require("../../Assets/images/Slider/town/comunes/desktop/parrilla.jpg"),paragraph:"Disfruta cocinando en compañía de tu familia y/o amigos con una increíble vista de la ciudad."},
                {title:"PARRILLA GOURMET",icon:"parrilla",desktop:require("../../Assets/images/Slider/town/comunes/desktop/parrilla-terraza.jpg"),movil:require("../../Assets/images/Slider/town/comunes/desktop/parrilla-terraza.jpg"),paragraph:"Disfruta cocinando en compañía de tu familia y/o amigos con una increíble vista de la ciudad."},
                {title:"ÁREA CHILL",icon:"area-chill",desktop:require("../../Assets/images/Slider/town/comunes/desktop/chill.jpg"),movil:require("../../Assets/images/Slider/town/comunes/desktop/chill.jpg"),paragraph:"Después de un día lleno de actividades, que mejor que relajarse con una película."},
            ],
            interiores:[
                {title:"COCINA",icon:"refri",desktop:require("../../Assets/images/Slider/town/interiores/desktop/cocina-1.jpg"),movil:require("../../Assets/images/Slider/town/interiores/desktop/cocina-1.jpg"),position:"center 50%",paragraph:"Cocina de concepto abierto con detalles en granito, muebles altos y bajos con luminarias elegantes para hacer de este un espacio acogedor."},
                // {title:"SALA COCINA",icon:"relax",desktop:require("../../Assets/images/Slider/town/interiores/desktop/sala_comedor-2.jpg"),movil:require("../../Assets/images/Slider/town/interiores/desktop/sala_comedor-2.jpg"),paragraph:"Ambientes integrados con excelente distribución y ahorro de espacio."},
                {title:"SALA COMEDOR",icon:"sala-estar",desktop:require("../../Assets/images/Slider/town/interiores/desktop/sala_comedor.jpg"),movil:require("../../Assets/images/Slider/town/interiores/desktop/sala_comedor.jpg"),position:"center 50%",paragraph:"Espacios funcionales conectados por estilo, diseño y una perfecta distribución de los ambientes."},
                {title:"DORMITORIO PRINCIPAL",icon:"bed",desktop:require("../../Assets/images/Slider/town/interiores/desktop/dormitorio.jpg"),movil:require("../../Assets/images/Slider/town/interiores/desktop/dormitorio.jpg"),position:"center 75%",paragraph:"Habitación ideal para tu día a día. Buena luz natural con amplio espacio."},
            ],
            exteriores:{
                cantButton:4,
                title:"Avance de obra Febrero",
                desktop:[
                    {img:require("../../Assets/images/Slider/exteriores/ingreso-town.jpg")},
                    {img:require("../../Assets/images/Slider/exteriores/dia/town.jpg")},
                    {img:require("../../Assets/images/Slider/exteriores/noche/town.jpg")},
                ]
            },
            depa_piloto:[],
            recorrido:"https://kuula.co/share/collection/7lX4n?fs=1&vr=1&sd=1&initload=0&thumbs=3&inst=es&info=0&logo=-1",
            avance:{
                cantButton:4,
                title:"Avance de obra Febrero",
                desktop:[]
            },
            end_project:{
                cantButton:5,
                title:"",
                images:[
                    {img:require("../../Assets/images/Slider/town/end_project/img-2.jpeg")},
                    {img:require("../../Assets/images/Slider/town/end_project/img-3.jpeg")},
                    {img:require("../../Assets/images/Slider/town/end_project/img-4.jpeg")},
                    {img:require("../../Assets/images/Slider/town/end_project/img-5.jpeg")},
                    {img:require("../../Assets/images/Slider/town/end_project/img-6.jpeg")},
                    {img:require("../../Assets/images/Slider/town/end_project/img-1.jpeg")},
                    {img:require("../../Assets/images/Slider/town/end_project/Ingreso.jpeg")},
                ]
            },
        },
        form:[
            {value:2,title:"2 Dormitorios"},
            {value:3,title:"3 Dormitorios"}
        ],
        info:{
            title:"CERCA DE ",subtitle:"TODO",
            entrega:'Entrega Inmediata',
            frase:'EN EL CORAZÓN DE <span class="bold">SANTA BEATRIZ</span>',
            pisos:"17",depas:"64",dorms:"3 Dorms.",ubicacion:"Av. Petit Thouars N° 1281, Santa Beatriz ",precio:"S/438,000",desde:"60m²",hasta:"67m²",
            fachada:require("../../Assets/images/fachada/Slide/TOWN.jpg"),
            fachadaMinified:require("../../Assets/images/fachada/Slide/resize/resize-TOWN.jpg"),
            // ingreso:require("../../Assets/images/fachada/town/ingreso.jpg"),
            parrafo:'<p class="paragraph">Town es la opción ideal para disfrutar tu propio espacio conectado a todas las comodidades que esperabas y rodeado de áreas verdes, lugares gastronómicos, de entretenimiento y cultura; con acceso a las principales vías de la ciudad. </p><p class="paragraph paragraph-2">Descubre los beneficios de este proyecto con excelentes áreas sociales diseñadas para tu estilo de vida y la de tu familia.</p>',
            parrafoArquitectura1:'Una fachada contemporánea y atemporal.  <div class="span"></div>Con una distribución exclusiva en el interior albergando hasta 4 departamentos en cada piso.',
            brochure:'/brochure/Brochure-town.pdf',
            arquitectura:'<span class="bold">ARQUITECTURA </span>& DISEÑO',
            mapa:require("../../Assets/mapas/Town.jpg"),
            sitios:[
                {title:"Supermercados",sitio:["Metro","Plaza Vea","Maxiahorro","Mass"]},
                {title:"Centros comerciales",sitio:["C.C. Risso","Real Plaza Centro Cívico"]},
                {title:"Universidades e institutos",sitio:["UTP","Telesup","Norbeth Wiener"],},
                {title:"Colegios",sitio:["Colegio 10 De Octubre","Colegio Elvira García y García"]},
                {title:"Entretenimiento",sitio:["Teatro Pirandello","Parque de la Reserva","Campo de Marte","Estadio Nacional"]},
                {title:"Centros médicos",sitio:["Hospital Rebagliati"]},
            ]
        },
        slideDepa:[
            {desktop:require("../../Assets/images/Slider/town/sliderMain/newTown-1.jpg"),movil:require("../../Assets/images/Slider/town/sliderMain/newTown-1.jpg")},
            {desktop:require("../../Assets/images/Slider/town/sliderMain/newTown-1-1.jpg"),movil:require("../../Assets/images/Slider/town/sliderMain/newTown-1-1.jpg")},
            {desktop:require("../../Assets/images/Slider/town/sliderMain/newTown-2.jpg"),movil:require("../../Assets/images/Slider/town/sliderMain/newTown-2.jpg")},
            {desktop:require("../../Assets/images/Slider/town/sliderMain/newTown-3.jpg"),movil:require("../../Assets/images/Slider/town/sliderMain/newTown-3.jpg")},
            {desktop:require("../../Assets/images/Slider/town/sliderMain/newTown-4.jpg"),movil:require("../../Assets/images/Slider/town/sliderMain/newTown-4.jpg")},
            {desktop:require("../../Assets/images/Slider/town/sliderMain/newTown-5.jpg"),movil:require("../../Assets/images/Slider/town/sliderMain/newTown-5.jpg")},
        ],
        atributos:[
            {icon:"camara",text:"<strong>Cámaras de seguridad</strong></br> en áreas comunes"},
            {icon:"cercania",text:"Cercano a <strong>vías <br>de rápido acceso</strong>"},
            {icon:"sala-de-espera",text:"<strong>Lobby &</br> sala de espera</strong>"},
            {icon:"sala-estar",text:"<strong>Sala de Usos</br>Múltiples</strong>"},
            {icon:"jardin",text:"<strong>Terraza jardín</strong>"},
            {icon:"parrilla",text:"Parrilla</br><strong>Gourmet</strong>"},
            {icon:"sala-coworking",text:"<strong>Área chill</strong>"},
            {icon:"gas",text:`<span class="text" style="text-wrap: balance;display:flex;flex-direction: column;">Redes internas de Gas Natural <br><span style="font-weight: 100;font-size: .7rem;opacity: .8;">*Sujeto a conexión de Cálidda</span></span>`},
            {icon:"none desktop",text:""},
        ],
        tipologias:{
            page:"town",
            template_id: 88,
            buttons:[{text:"01",value:1},{text:"02",value:2},{text:"03",value:3},{text:"Todos",value:"all"},],
            brand:require('../../Assets/images/iconos/brand-town.jpg'),
            cards: [
                // {id: 44,type:"Modelo 204",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/town/Modelo-204.jpg"),metersTotal:"60,91 m²",areaTechada:"58,62 m²",areaLibre:"2,29 m²",units:[{price:''}], units_available: 0},
                // {id: 37,type:"Modelo XO1",dorms:"3",vista:"exterior",img:require("../../Assets/images/tipologias/town/01.jpg"),metersTotal:"67,75 m²",units:[{price:''}], units_available: 0},
                // {id: 39,type:"Modelo X02-A",dorms:"3",vista:"exterior",img:require("../../Assets/images/tipologias/town/02a.jpg"),metersTotal:"64,30 m²",units:[{price:''}], units_available: 0},
                // {id: 40,type:"Modelo X02-B",dorms:"3",vista:"exterior",img:require("../../Assets/images/tipologias/town/02b.jpg"),metersTotal:"68,58 m²",units:[{price:''}], units_available: 0},
                // {id: 42,type:"Modelo X03-A",dorms:"3",vista:"interior",img:require("../../Assets/images/tipologias/town/03a.jpg"),metersTotal:"67,09 m²",units:[{price:''}], units_available: 0},
                // {id: 43,type:"Modelo X03-B",dorms:"1",vista:"interior",img:require("../../Assets/images/tipologias/town/x03b.png"),metersTotal:"60,20 m²",units:[{price:''}], units_available: 0},
                // {id: 45,type:"Modelo X04-A",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/town/04a.jpg"),metersTotal:"58,62 m²",units:[{price:''}], units_available: 0},
            ],
        }
}
export default town