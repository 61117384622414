import * as Yup from 'yup';

export const BaseInterestedConstraints = {
    fname: Yup.string()
        .required(),
    lname: Yup.string()
        .required(),
    email: Yup.string()
        .email()
        .required(),
    telephone: Yup.string()
        .matches(/[\+?\-?0-9]{7,}/)
        .required(),
}

export const BudgetValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        floor: Yup.string()
    })

export const QuoterValidatonSchema = (values) => Yup.object()
    .shape({
        price: Yup.string(),
        initial_fee: Yup.string()
            .required(),
        afp_savings: Yup.string(),
        floor: Yup.string(),
        salary: Yup.string()
            .required(),
        motive: Yup.string()
            .required()
    })

export const ContactValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        rooms_amount: Yup.string()
            .required(),
        time_of_day: Yup.string()
            .required()
    })

export const SchemaFormReferidos = (values) => Yup.object()
    .shape({
        owner_fname: Yup.string()
            .required(),
        owner_lname: Yup.string()
            .required(),
        owner_email: Yup.string()
            .email()
            .required(),
        owner_telephone: Yup.string()
            .matches(/[\+?\-?0-9]{7,}/)
            .required(),
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })
export const HomeContactValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        time_of_day: Yup.string()
            .required(),
        project: Yup.string()
            .required(),
        rooms_amount: Yup.string()
            .when("project", {
                is: (val) => val,
                then: Yup.string().required()
            }),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const ContactUsValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        rooms_amount: Yup.string()
            .when("project", {
                is: (val) => val,
                then: Yup.string().required()
            }),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const BaseValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const PostSaleValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        apartment_number: Yup.string()
            .required(),
        message: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const SellPropertyValidationSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        id_type: Yup.string()
            .required(),
        id_number: Yup.string()
            .required(),
        sell_as: Yup.string()
            .required(),
        terrain_area: Yup.string()
            .required(),
        district: Yup.string()
            .required(),
        address: Yup.string()
            .required(),
        currency: Yup.string()
            .required(),
        terrain_value: Yup.string()
            .required(),
        message: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const BookReclamationSchema = (values) => Yup.object()
    .shape({
        name: Yup.string()
            .required(),
        email: Yup.string()
            .email()
            .required(),
        telephone: Yup.string()
            .matches(/[\+?\-?0-9]{7,}/)
            .required(),
        project: Yup.string()
            .required(),
        address: Yup.string()
            .required(),
        region: Yup.string()
            .required(),
        province: Yup.string()
            .required(),
        district: Yup.string()
            .required(),
        idType: Yup.string()
            .required(),
        idNumber: Yup.string()
            .required(),
        // mom_dad: Yup.string()
        //     .required(),
        // mom_dad_id_umber: Yup.string()
        //     .required(),
        good_type: Yup.string()
            .required(),
        descripcion_prodserv: Yup.string()
            .required(),
        tipo: Yup.string()
            .required(),
        descripcion_tipo: Yup.string()
            .required(),
        pedido: Yup.string()
            .required(),
        notificacion: Yup.string()
            .required(),
        // publicity_consent: Yup.bool()
        //     .oneOf([true]).required(),
        // share_consent: Yup.bool()
        //     .oneOf([true]).required(),
        terms: Yup.bool()
            .oneOf([true]).required(),
    })

export const CheckoutValidationSchema = (values) => Yup.object().shape({
    // project: Yup.string()
    //     .required(),
    fname: Yup.string()
        .required(),
    lname: Yup.string()
        .required(),
    email: Yup.string()
        .email()
        .required(),
    telephone: Yup.string()
        .matches(/[\+?\-?0-9]{7,}/)
        // .test("telephone", val =>  val && val.toString().length >= 7)
        .required(),
    invoice: Yup.boolean(),
    dni: Yup.string()
        .when('invoice', {
            is: value => value == false,
            then: Yup.string().required()
        }),
    address: Yup.string()
        .when('invoice', {
            is: value => value == false,
            then: Yup.string().required()
        }),
    budgetNumber: Yup.number(),
    companyName: Yup.string()
        .when('invoice', {
            is: value => value == true,
            then: Yup.string().required()
        }),
    ruc: Yup.string()
        .when('invoice', {
            is: value => value == true,
            then: Yup.string().required()
        }),
    fiscalAddress: Yup.string()
        .when('invoice', {
            is: value => value == true,
            then: Yup.string().required()
        }),
    // consent: Yup.bool()
    //     .test('consent', 'You have to agree with our Terms and Conditions!', value => value === true)
    //     .required('You have to agree with our Terms and Conditions!'),
})
