import React, { Component } from 'react'
import "./slider.scss";
import Slider from "react-slick";
import Skeleton from 'react-loading-skeleton';
import { ShapeSquare } from '../UI/shapeSquare';
export default class Sliderdeimagenes extends Component {
    constructor(props){
        super(props)
        this.afterChange = this.afterChange.bind(this)
        this.next = this.next.bind(this)
        this.prev = this.prev.bind(this)
        this.state = {
            settings:{
                dots: false,
                arrows:false,
                infinite: true,
                speed: 500,
                pauseOnHover:false,
                autoplay: true,
                autoplaySpeed: 5000,
                slidesToShow: 1,
                slidesToScroll: 1,
                afterChange:this.afterChange
            },
        }
    }
    afterChange(e){
        this.setState({activeSlider:e})
    }
    next(){
        this.slider.slickNext()
    }
    prev(){
        this.slider.slickPrev()
    }
    render() {        
        return (
            <div className="SliderDepartamentos">
                {
                    this.props.img.length > 0 ? <Slider {...this.state.settings} ref={slider => (this.slider = slider)}>
                        {this.props?.img.map((item,idx)=>{
                            return(
                                <div className='background d-flex' key={idx+'slider-departamento'}>
                                   
                                    <picture>
                                        <source className="background " media="(min-width: 500px)" srcset={item.banner_desktop?.url}></source> 
                                        <img className='background' src={item.banner_mobile?.url}></img>
                                    </picture>
                                </div>
                            )
                        })}

                    </Slider> : 
                    <ShapeSquare />
                }
                {
                    this.props.img.length == 1 ? <></> : 
                    <div className="container">
                        <div className="signal">
                            <div className="btn" onClick={()=>this.prev()}>
                                <div className="signal-prev mask"></div>
                            </div>
                            <div className="btn" onClick={()=>this.next()}>
                                <div className="signal-next mask"></div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
