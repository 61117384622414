import React, { Component } from 'react'
import "./mapa.scss"
import sr from '../scrollreveal/scrollreveal'
import $ from 'jquery';
import { ShapeSquare } from '../UI/shapeSquare';

export default class Mapa extends Component {
    constructor(props){
        super(props)
    }
    componentDidMount(){
        $(document).ready(function(){
            sr.reveal(".mapa", {origin:"bottom",duration:1800,distance:"100px",delay:200,reset:false,easing:"ease-out"});
            sr.reveal(".info-mapa", {origin:"bottom",duration:1800,distance:"100px",delay:200,reset:false,easing:"ease-out"});
        });
    }
    render() {
        return (
            <section id="ubicacion" className={"Mapa-all "+this.props.page}>
                <h2 className="title container"><span className='bold'>UBICACIÓN</span> IDEAL</h2>
                <div className='container mapa'>
                    <div className="content-row">
                        <div className='mapa d-block'>
                            {
                                this.props.image_map !== false ? <img className='' src={this.props.image_map.url}></img> : <ShapeSquare />
                            }
                        </div>
                        <div className='info-mapa'>
                                <h3 className="title">Todo está cerca, llega en menos de 15 minutos a:</h3>
                                {this.props.places && this.props.places.map((element)=>{
                                    return  <div className="flex-column">
                                                <h6 className="sub-title">{element.title_group}</h6>
                                                {element.places && element.places.map((items)=>{
                                                    return  <span className='direction'>{items.name_place}</span>
                                                })}
                                            </div>
                                    
                                    })}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
