import React, { Component } from 'react'
import Icon from '../Icon';
import './loading.scss'
import logo from './logo.json'
export default class Loading extends Component {
    constructor(props){
        super(props)
        this.hideLoading = this.hideLoading.bind(this)
        this.state = {
            value:"",
            active:false,
            hide:false,
        }
    }
    componentDidMount(){

    }
    hideLoading(){
        this.setState({hide:true})
    }
    render() {
        return (
            <div className={`Loading ${this.state.hide ? "inactive" : ""}`}>
                <div className="logo">
                    <Icon icon={logo} ref={element => {this.logo = element;}} event={this.hideLoading}></Icon>
                </div>
            </div>
        )
    }
}
