const nou = {
	sperant_id: 16,
	route: '/nou',
	page: 'nou',
	district: 'Pueblo Libre',
	whatsapp: ['994006347'],
	titlehead:"Proyecto Nou | Departamentos en Pueblo Libre",
    metadescription:"Vive en una ubicación estratégica en Pueblo Libre. Departamentos de 2 y 3 dormitorios desde S/423,400 en Entrega Inmediata. Nou es la opción ideal para disfrutar tu propio espacio rodeado de todas las comodidades que esperabas. Cotiza aquí.",
    metakeywords:"departamentos en pueblo libre, departamentos en venta en pueblo libre, inmobiliaria, pueblo libre, casaideal inmobiliaria, departamentos en la avenida brasil, departamentos de 2 y 3 dormitorios, edificio en entrega inmediata, departamentos en entrega inmediata",
	
	iziPubKey: {
		prod: '49596977:publickey_GFHM0KK4eijwSWc1vrHLUkB5uGMATCADODTloZz1ElwkD',
		dev: '49596977:testpublickey_HUgEIProEd6gJNDhtKNdED6zWsFxVepdvr396ATnOZpeS',
	},
	
	areas: {
		cant: 4,
		comunes: [
		],
		interiores: [
		],
		exteriores: {
			cantButton: 4,
			title: 'Avance de obra Marzo',
			desktop: [{ img: require('../../Assets/images/Slider/exteriores/dia/nou.jpg') }, { img: require('../../Assets/images/Slider/exteriores/noche/nou.jpg') }],
		},
		recorrido: 'https://kuula.co/share/collection/7lXvY?fs=1&vr=1&sd=1&initload=0&thumbs=3&inst=es&info=0&logo=-1',
		avance: {
			cantButton: 4,
			title: 'Avance de obra Mayo',
			desktop: [
				// {img:require("../../Assets/images/Slider/brasil/avance/1.jpg")},
				// {img:require("../../Assets/images/Slider/brasil/avance/2.jpg")},
				// {img:require("../../Assets/images/Slider/brasil/avance/3.jpg")},
				// {img:require("../../Assets/images/Slider/brasil/avance/4.jpg")},
				// {img:require("../../Assets/images/Slider/brasil/avance/5.jpg")},
			],
		},
		end_project: {
			cantButton: 5,
			title: '',
			images: [
			],
		},
		depa_piloto: [
		],
	},
	info: {
		title: 'VERSÁTIL',
		subtitle: '& PERFECTO',
		pisos: '22',
		depas: '137',
		dorms: `3 dorms`,
		ubicacion: 'Av. Brasil N° 2790, Pueblo Libre',
		precio: 'S/484,300',
		desde: '69m²',
		hasta: '',
		entrega: 'Entrega Inmediata',
		entregamovil: 'Entrega Inmediata',
		frase: 'UBICACIÓN ESTRATÉGICA EN <span class="bold">PUEBLO LIBRE</span>',
		fachada: require('../../Assets/images/fachada/Slide/NOU.jpg'),
		fachadaMinified: require('../../Assets/images/fachada/Slide/resize/resize-NOU.jpg'),
		// ingreso:require("../../Assets/images/fachada/nou/ingreso.jpg"),
		parrafo: '<p class="paragraph paragraph-1">NOU es la opción ideal para disfrutar tu propio espacio rodeado de todas las comodidades que esperabas.</p><p class="paragraph paragraph-2">Descubre los beneficios de este moderno concepto que entrega versátiles áreas sociales diseñadas para tu estilo de vida y la de tu familia, junto a departamentos con gran iluminación natural.</p>',
		parrafoArquitectura1: 'Una fachada icónica con elegancia en cada detalle.<div class="span"></div>Ubicado en una esquina para potenciar la iluminación natural de cada ambiente.',
		brochure: '/brochure/Brochure-nou.pdf',
		arquitectura: '<span class="bold">ARQUITECTURA </span>& DISEÑO',
		mapa: require('../../Assets/mapas/nou.jpg'),
		sitios: [
			{ title: 'Supermercados', sitio: ['Plaza Vea', 'Candy'] },
			{ title: 'Centros comerciales', sitio: ['C.C. Risso', 'Real Plaza Salaverry'] },
			{ title: 'Universidades e institutos', sitio: ['Universidad de Pacifico', 'UPC', 'ISIL'] },
			{ title: 'Colegios', sitio: ['Pamer', 'Colegio de Jesús'] },
			{ title: 'Entretenimiento', sitio: ['C.C. Peruano Japones'] },
			{ title: 'Centros médicos', sitio: ['Hospital Militar', 'Hospital de la Policía'] },
		],
	},
	form: [
		{ value: 3, title: '3 Dorms' },
	],
	slideDepa: [
	
	],
	atributos: [
		
		{ icon: 'camara', text: '<strong>Cámaras de seguridad</strong></br> en áreas comunes' },
		{ icon: 'lobby', text: 'Lobby de </br><strong>doble altura</strong>' },
		{ icon: 'parrilla', text: 'Zona de <strong>parrillas,</strong></br><strong>Pizza & Bar</strong>' },
		{ icon: 'jardin', text: '<strong>Jardín Zen</strong>' },
		{ icon: 'sala-coworking', text: '<strong>Sala Coworking</strong>' },
		{ icon: 'sala-estar', text: '<strong>Sala de Estar</strong>' },
		{ icon: 'area-child', text: '<strong>Sala de niños</strong>' },
		{ icon: 'game', text: '<strong>Sala de juegos</strong>' },
		{ icon: 'yoga', text: '<strong>Yoga y bienestar</strong>' },
		{ icon: 'fogata', text: '<strong>Relax zone</strong>' },
		{icon:"gas",text:`<span class="text" style="text-wrap: balance;display:flex;flex-direction: column;">Redes internas de Gas Natural <br><span style="font-weight: 100;font-size: .7rem;opacity: .8;">*Sujeto a conexión de Cálidda</span></span>`},
		{ icon: 'none', text: '' },
	],
	tipologias: {
		page: 'nou',
		template_id: 87,
		buttons: [
			{ text: '02', value: 2 },
			{ text: '03', value: 3 },
			{ text: 'Todos', value: 'all' },
		],
		brand: require('../../Assets/images/iconos/brand-nou.jpg'),
		cards: [
			
		],
	},
};

export default nou;
