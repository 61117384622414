export const configSlider = {
    dots: false,
    arrows:false,
    infinite: false,
    speed: 300,
    pauseOnHover:false,
    autoplay: false,
    autoplaySpeed: 3000,
    variableWidth: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
    ]
}