import React, { Component } from 'react'
import TimeProjects from '../../components/timeProjects/timeProjects'

export default class ProyectosEntregados extends Component {
    componentDidMount(){
        window.scrollTo(0,0)
    }
    render() {
        return (
            <section className="nosotros proyectos-entregados">
                <section className="timing">

                    <h2 className="title" id="proyectos-entregados">Proyectos entregados</h2>
                    {/*  */}
                    <TimeProjects></TimeProjects>
                </section>
            </section>
        )
    }
}
