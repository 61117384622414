import React, { Component } from 'react'
import "../nosotros/nosotros.scss"
export default class TimeProjects extends Component {
    render() {
        const linea = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.71 5316.56"><defs><style>.cls-1,.cls-3{fill:none;stroke-miterlimit:10;stroke-width:2px;}.cls-1{stroke:#eaeaea;}.cls-2{fill:#e41426;}.cls-3{stroke:#fff;}</style></defs><title>niÃ±oRecurso 212BOTONES</title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><line class="cls-1" x1="35.36" y1="69.59" x2="35.36" y2="1541"></line><circle class="cls-2" cx="35.36" cy="35.36" r="35.36" id="circulo" ></circle><circle class="cls-2" cx="35.36" cy="285.56" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="704.04" r="9.24"></circle><polyline class="cls-3" points="43.24 31.24 35.35 39.12 27.47 31.24"></polyline><circle class="cls-2" cx="35.36" cy="1122.52" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="1541" r="9.24"></circle><line class="cls-1" x1="35.36" y1="1541" x2="35.36" y2="2796.44"></line><circle class="cls-2" cx="35.36" cy="1541" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="1959.48" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="2377.96" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="2796.44" r="9.24"></circle><line class="cls-1" x1="35.36" y1="2796.44" x2="35.36" y2="4051.88"></line><circle class="cls-2" cx="35.36" cy="2796.44" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="3214.92" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="3633.4" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="4051.88" r="9.24"></circle><line class="cls-1" x1="35.36" y1="4051.88" x2="35.36" y2="5307.31"></line><circle class="cls-2" cx="35.36" cy="4051.88" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="4470.35" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="4888.83" r="9.24"></circle><circle class="cls-2" cx="35.36" cy="5307.31" r="9.24"></circle></g></g></svg>'
        const circulo = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.35 46.35"><defs><style>.modelo-circulo{fill:#e41426;}.modelo-circulo2{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;}</style></defs><title>niÃ±oRecurso 312BOTONES</title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><circle class="modelo-circulo" cx="23.18" cy="23.18" r="23.18"/><polyline class="modelo-circulo2" points="30.99 19.23 23.11 27.12 15.22 19.23"/></g></g></svg>'
        return (
            <div className="time-projects">
                <img class="tramado-1"src={require("../../Assets/images/iconos/tramado-1.png")}></img>
                <img class="tramado-2"src={require("../../Assets/images/iconos/tramado-2.png")}></img>
                <img class="tramado-3"src={require("../../Assets/images/iconos/tramado-3.png")}></img>

                <div class="linea" dangerouslySetInnerHTML={{__html:linea}}>
                    </div>
                    {/* 2024 – Nou */}
                    <div className="time flex-row order">
                        <div className="time-paragraph time-left">
                            <span className="sub-title"> 2023 – Nou</span>
                            <p className="parrafo">Nou, es la opción ideal para disfrutar de tu propio espacio rodeado de todas las comodidades que esperabas. Un moderno concepto que entrega versátiles áreas sociales diseñadas para tu estilo de vida y la de tu familia, junto a depas con gran iluminación natural.</p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                            Av. Brasil N° 2790, Pueblo Libre
                            </p>
                        </div>
                        <div className="imagen"> <img alt="nosotros paseo del parque" className='' src={require('../../Assets/images/proyectos/renders/render-nou.JPG')}></img> </div>
                    </div>
                    {/* 2020 – salaverry */}
                    <div className="time flex-row order">
                        <div className="imagen"> <img alt="nosotros paseo del parque" className='' src={require('../../Assets/images/proyectos/renders/fachada-trend.JPG')}></img> </div>
                        <div className="time-paragraph">
                            <span className="sub-title"> 2023 – Trend</span>
                            <p className="parrafo">Hay una historia que compartimos y una historia personal. Una vida en común, y una vida propia: La de tu familia, la de tus hijos y la de sus estudios. La de su futuro. Trend está a un paso de todo lo que tu familia necesita: Educación, cultura y espacios verdes.</p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                            Av. Mariano Cornejo N° 1848, Cercado de Lima

                            </p>
                        </div>
                    </div>
                    {/* 2020 – salaverry */}
                    <div className="time flex-row ">
                        <div className="time-paragraph time-left">
                            <span className="sub-title"> 2022 – Town</span>
                            <p className="parrafo">Town es la opción ideal para disfrutar tu propio espacio conectado a todas las comodidades que esperabas y rodeado de áreas verdes, lugares gastronómicos, de entretenimiento y cultura; con acceso a las principales vías de la ciudad.</p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                Av. Petit Thouars N° 1281, Santa Beatriz
                            </p>
                        </div>
                        <div className="imagen time-rigth"> <img alt="nosotros paseo del parque" className='' src={require("../../Assets/images/proyectos/renders/town.png")}></img> </div>
                    </div>
                    {/* 2020 – salaverry */}
                    <div className="time flex-row order">
                        <div className="imagen"> <img alt="nosotros paseo del parque" className='' src={require('../../Assets/images/fachada/salaverry/ingreso.jpg')}></img> </div>
                        <div className="time-paragraph">
                            <span className="sub-title"> 2020 – M-salaverry</span>
                            <p className="parrafo">Es una forma de vivir que sucede en las ciudades, espacios cuidados donde la conexión wifi es tan rápida como la avenida que te conecta al trabajo o a donde quieras llegar.</p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                Av. Salaverry 2124Jesús María
                            </p>
                        </div>
                    </div>
                    {/* 2016 – Paseo del Parque */}
                    <div className="time flex-row ">
                        <div className="time-paragraph time-left">
                            <span className="sub-title"> 2016 – Paseo del Parque</span>
                            <p className="parrafo">Condominio Paseo del Parque tiene un moderno diseño con extensas áreas comunes y a minutos de la estación Matellini del Metropolitano.</p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                Jr. Universo 305, Urb. La Campiña, Chorrillos.
                            </p>
                        </div>
                        <div className="imagen time-rigth"> <img alt="nosotros paseo del parque" className='' src={require('../../Assets/nosotros/Paseo-del-parque.jpg')}></img> </div>
                    </div>
                    {/* almudena */}
                    <div className="time flex-row order">
                        <div className="imagen "> <img className='' src={require('../../Assets/nosotros/Edificio-Almudena.jpg')}></img> </div>
                        <div className="time-paragraph ">
                            <span className="sub-title">2015 – Almudena</span>
                            <p className="parrafo">Cuenta con atractivas áreas comunes como salón para niños, sala de padres, terraza con BBQ para toda la familia y estacionamientos para las visitas.  </p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                Av. Arnaldo Márquez 1124, Jesús María.
                            </p>
                        </div>
                    </div>
                    {/* 2014 – Bilbao */}

                    <div className="time flex-row">
                        <div className="time-paragraph time-left">
                            <span className="sub-title">2014 – Bilbao </span>
                            <p className="parrafo">Flat y dúplex de 2 y 3 dormitorios. Cuenta con salón de reuniones, terraza y BBQ, y un salón de juegos para toda la familia.</p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                    Ca. Bilbao 333, Urb. Orrantia - Magdalena del Mar.
                            </p>
                        </div>
                        <div className="imagen time-rigth"> <img alt="edificio bilbao" className='' src={require('../../Assets/nosotros/Edifcio-Bilbao.jpg')}></img> </div>
                    </div>
                    {/*  2013 – Alto Mirador 2 */}

                    <div className="time flex-row order">
                        <div className="imagen "> <img alt="alto mirador" className='' src={require('../../Assets/nosotros/Alto-Mirador-2.jpg')}></img> </div>
                        <div className="time-paragraph">
                            <span className="sub-title">2013 – Alto Mirador 2</span>
                            <p className="parrafo">Un moderno edificio de 20 pisos cuenta con piscina, terraza BBQ, sala de juegos para niños, gimnasio equipado, sala de usos múltiples, lobby de ingreso a doble altura. </p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                            Av. Brasil 1399, Jesús María.
                            </p>
                        </div>

                    </div>
                    {/* 2012 – Arona */}

                    <div className="time flex-row">
                        <div className="time-paragraph time-left">
                            <span className="sub-title">2012 – Arona</span>
                            <p className="parrafo">Departamentos de 2 y 3 dormitorios, con excelente distribución e iluminación. Cuenta con ascensor, zona de BBQ y área de juegos para niños.</p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                Jr. San Martín 450, San Miguel.
                            </p>
                        </div>
                        <div className="imagen time-rigth"> <img alt="edificio Arona" src={require('../../Assets/nosotros/Edificio-Arona.jpg')}></img> </div>
                    </div>
                    {/* 2012 – Sevilla */}

                    <div className="time flex-row order">
                        <div className="imagen"> <img alt="edificio Sevilla" className='' src={require('../../Assets/nosotros/Edificio-Sevilla.jpg')}></img> </div>
                        <div className="time-paragraph">
                            <span className="sub-title">2012 – Sevilla</span>
                            <p className="parrafo">Flat y duplex de 1 y 3 dormitorios, con áreas ocupadas desde 99 a 256 m2. Cada departamento cuenta con estacionamiento para 2 autos y un depósito. </p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                Jr. Sevilla 181-197, Urb. Higuereta, Surco. 
                            </p>
                        </div>

                    </div>
                    {/* 2011 – Hermano Lobo */}
                    <div className="time flex-row ">
                        <div className="time-paragraph time-left">
                            <span className="sub-title ">2011 – Hermano Lobo</span>
                            <p className="parrafo">flat y dúplex de 3 y 4 dormitorios, cada uno con dos estacionamientos paralelos y un depósito incluido. </p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                Jr. Hermano Lobo 175, Urb. Chacarilla del Estanque, San Borja.
                            </p>
                        </div>
                        <div className="imagen time-rigth"> <img className='' alt="Hermano lobo" src={require('../../Assets/nosotros/Hermano-Lobo.jpg')}></img> </div>
                    </div>

                    {/*  2010 – Alto Mirador */}
                    <div className="time flex-row order">
                        <div className="imagen "> <img alt="edificio Alto mirador" src={require('../../Assets/nosotros/Edificio-Alto-Mirador-1.jpg')}></img> </div>
                        <div className="time-paragraph ">
                            <span className="sub-title"> 2010 – Alto Mirador</span>
                            <p className="parrafo">Departamentos de 1, 2 y 3 dormitorios, todos con vista a la calle y excelente iluminación.</p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                Av. Brasil 1400, esquina con el Jr. Pedro Morales Bermúdez, Pueblo Libre.
                            </p>
                        </div>

                    </div>
                    {/* 2010 – Allegro  */}

                    <div className="time flex-row ">
                        <div className="time-paragraph time-left">
                            <span className="sub-title">2010 – Allegro</span>
                            <p className="parrafo">Departamentos de 3 dormitorios con hermosa vista a parque y excelente iluminación. El edificio cuenta un ascensor y todos los departamentos incluyen dos estacionamientos y un depósito.
                                Moderno ascensor con capacidad para 8 pasajeros.
                            </p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                            Dirección: Av. San Borja Norte 1702, Urb. Mariscal Castilla, San Borja.
                            </p>
                        </div>
                        <div className="imagen time-rigth"> <img alt="edificio Allegro" src={require('../../Assets/nosotros/Edificio-Allegro.jpg')}></img> </div>
                    </div>

                    {/*  2008 – Parque Sol */}
                    <div className="time flex-row order">
                        <div className="imagen "> <img alt="edificio Parque del Sol" src={require('../../Assets/nosotros/Edificio-Parque-Sol.jpg')}></img> </div>
                        <div className="time-paragraph ">
                            <span className="sub-title">2008 – Parque Sol</span>
                            <p className="parrafo">Departamentos de 2 y 3 dormitorios. El edificio cuenta un ascensor y todos los departamentos incluyen dos estacionamientos. </p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                    Parque Pedro la Rosa 121, Urb San Felipe - Jesús María. 
                            </p>
                        </div>

                    </div>
                    {/* 2008 – Terrazul */}
                    <div className="time flex-row">
                        <div className="time-paragraph time-left">
                            <span className="sub-title">2008 – Terrazul</span>
                            <p className="parrafo">Departamentos de 3 dormitorios con área de servicio, todos con vista a la calle. Estacionamiento en el sótano y primer piso.</p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                Av. Sergio Bernales 297, Urb. Barrio Médico, Surquillo.
                            </p>
                        </div>
                        <div className="imagen time-rigth"> <img alt="edificio Terrazul" src={require('../../Assets/nosotros/Edificio-Terrazul.jpg')}></img> </div>
                    </div>

                    {/* 2007 – Castilla */}
                    <div className="time flex-row order">
                        <div className="imagen "> <img alt="edificio Castilla" src={require('../../Assets/nosotros/Residencial-Castilla.jpg')}></img> </div>
                        <div className="time-paragraph ">
                            <span className="sub-title">2007 – Castilla</span>
                            <p className="parrafo">Ubicado frente al remodelado parque Mariscal Castilla, con una hermosa vista panorámica al parque y a San Isidro.</p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                    Jr. Joaquín Bernal 880, Lince.
                            </p>
                        </div>
                    </div>

                    {/* los patriotas */}

                    <div className="time flex-row">
                        <div className="time-paragraph time-left">
                            <span className="sub-title">2005 – Los Patriotas</span>
                            <p className="parrafo">Departamentos de 1 y 3 dormitorios, todos con vista a la calle. Cuenta con aislamiento acústico de todos los ambientes.</p>
                            <span className="ubicacion">Ubicación</span>
                            <p className="parrafo">
                                Av. Los Patriotas 397, esquina con la Calle Américo Vespucio, San Miguel.
                            </p>
                        </div>
                        <div className="imagen time-rigth"> <img alt="edificio Los patriotas" src={require('../../Assets/nosotros/Condominio-Los-Patriotas.jpg')}></img> </div>
                    </div>
                                    

                    <div className="counter-1 flex-column">
                    <div className="ball circulo" dangerouslySetInnerHTML={{__html:circulo}}></div>
                    <div className="ball id-1"></div>
                    <div className="ball id-2"></div>
                    <div className="ball id-3"></div>
                    <div className="ball id-4"></div>
                    <div className="ball id-5"></div>
                    <div className="ball id-6"></div>
                    <div className="ball id-7"></div>
                    <div className="ball id-8"></div>
                    <div className="ball id-9"></div>
                    <div className="ball id-10"></div>
                    <div className="ball id-11"></div>
                    <div className="ball id-12"></div>
                    <div className="ball id-13"></div>
                    </div>
            </div>
        )
    }
}
