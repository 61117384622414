import React, { Component } from 'react'
import Data from '../../components/data/data'
import "./cart.scss"
import $ from 'jquery'
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { formatCurrency, hideCart, addCard ,deleteCard,updateTipologiesSelected,closeMessage} from '../../data/carts';
class Cart extends Component {
    constructor(props){
        super(props)
    }
    initSession(){
        let modal = document.querySelector(".modal-login")
        modal.classList.add("active")
    }
    componentDidMount(){
        try {
            let data = JSON.parse(localStorage.getItem("cards")) || [];
            let projectsCart = JSON.parse(localStorage.getItem("projects")) || []
            this.props.updateTipologiesSelected({data:data,projectsCart:projectsCart});
        } catch (err) {
            console.error(err)
        }
    }
    hideModal(event){
        let dialog = document.querySelector(".dialog-cart");
        if(dialog){
            if(dialog.dataset.active == "true"){
                dialog.classList.add("active")
                dialog.classList.remove("pasive")
                dialog.dataset.active = "false"
            }
            else if(dialog.dataset.active == "false" && event.target.dataset.type == "modal-cart"){
                dialog.classList.add("pasive")
                dialog.classList.remove("active")
                dialog.dataset.active = "true"
            }
        }
    }
      
    showPayment(){
        let element = document.querySelector(".content-cart-add");
        if(element){
            element.classList.add("hide")
        }
    }
    checkout(){
        document.querySelector(".modal-checkout").open = true
    }

    onCheckout(){
        window.dataLayer?.push({ ecommerce: null })  // Clear the previous ecommerce object.
        window.dataLayer?.push({
            event: "begin_checkout",
            ecommerce: {
              currency: this.props.value.tipologiesSelected[0].unit.currency,
              value: this.props.value.priceSeparation * this.props.value.tipologiesSelected.length,
              items: this.props.value.tipologiesSelected.map(type => (
                {
                    item_id: type.id,
                    item_name: type.unit.type,
                    item_brand: "Casaideal",
                    item_category: type.project.toLowerCase(),
                    item_category2: type.vista,
                    item_category3: type.dorms,
                    item_category4: type.unit.total_area,
                    item_variant: type.unit.name,
                    price: type.unit.price,
                    quantity: 1
                }
            ))
          }
        })

        setTimeout(() => {
            window.location.href = "/Realizar-compra"
        }, 1000);
    }

    
    render() {
        return(
            <dialog className={`dialog-cart ${this.props.value.activeCart == 1 ? "active" : ""} ${this.props.value.activeCart == 2 ? "hide" : ""}`} data-type="modal-cart"
                onClick={(e)=>{this.props.hideCart(e.target.dataset.type)}}
                 >
                <div className={`popup-card ${this.props.value.listProjects.length > 1 ? 'active' : 'inactive'} ${this.props.value.popupMessage == true ? "" : 'inactive'}`}>
                    <div className='close' onClick={(e)=>{this.props.closeMessage()}}>
                        <div className="icon-close"></div>
                    </div>
                    Para separar tus departamentos, debes elegir las tipologias de un proyecto a la vez.
                </div>
                <div className="content-cart-add">
                <div className="content-items">
                    <h2 className="title-cart">Depas seleccionados</h2>
                    <span data-type="modal-cart"  className="hide">Ocultar</span>
                    <div className="content-cart-list">
                        {
                            this.props.value.tipologiesSelected.map((e,index)=>{
                                return(
                                    <div key={index-"items"} className="item">
                                        <div className="content-attr">
                                            <span className="item-title"><img src={e?.imagen?.url}></img></span>
                                            <div className="content-item">
                                                <span className="item-price">Proyecto: <span className="price">{e.project}</span></span>
                                                <span className="item-price">Tipologia: <span className="price">{e.name}</span></span>
                                                <span className="item-price">Departamento: <span className="price">{e.unit ? e.unit.name : null}</span></span>
                                                <span className="item-price">N° de dorms: <span className="price">{e.dormitorio}</span></span>
                                                <span className="item-price">Precio de separación: <span className="price">s/ 1.500</span></span>
                                            </div>
                                        </div>
                                        <div className="options-cart">
                                            {/* <div className="icon-cart" onClick={(item) => context.btnMore(e)}><div className="btn-cart btn-cart-more" ></div></div>
                                            <span className="quantity">{e.cant}</span>
                                            <div className="icon-cart" onClick={(item) => context.btnLess(e)}><div className="btn-cart btn-cart-less"></div></div><div></div> */}
                                            <div className="icon-cart" onClick={() => this.props.deleteCard(e)}>
                                                <div className="btn-cart icon-trush"></div>
                                            </div>
                                            {/* onClick={(item) => context.deleteItem(e.id)} */}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <button className={`btn btn-cart payment ${this.props.value.tipologiesSelected.length == 0 ? 'hide' : ''} 
                    ${this.props.value.listProjects.length > 1 ? 'hide' : ''}
                `} data-type="modal-cart" onClick={e=>this.onCheckout()} >Realizar pago</button>
            </div>
        </dialog>
        )
    }
}
const MapStateProps = (state)=>{
    return(
        {
            value:state.cartStore
        }
    )
}
export default connect(MapStateProps,{formatCurrency,hideCart,addCard, deleteCard,updateTipologiesSelected,closeMessage})(Cart)