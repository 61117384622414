const print = {
    sperant_id: 23,
    route:"/print",
    page:"print",
    titlehead: "Proyecto Print | Departamentos en Chorrillos",
	metadescription:"Vive en la zona más estratégica de Chorrillos. Departamentos de 2 y 3 dorms desde S/327,800. Stay es un proyecto diseñado para familias pequeñas que viven a su propio ritmo y para familias grandes que buscan experiencias únicas. Cotiza aquí",
	metakeywords:"departamentos en chorrillos, departamentos en venta en chorrillos, inmobiliaria, chorrillos, casaideal inmobiliaria, departamentos de 2 y 3 dormitorios, edificio con entrega inmediata, departamentos con entrega inmediata",
    iziPubKey: {
        prod: "70370416:publickey_UdMIVzQjkfnCWXtQGAiwdzvhOsYgQl5PXuKxscQwrDgw2",
        dev: "70370416:testpublickey_2oLGfavT6dAblPP6FPZvPVNU1a4BaqUITImpgIAt4Kp2C",
    },
    tipologias: {
		page: 'unbox',
		template_id: 90,
		buttons: [
			{ text: '02', value: 2 },
			{ text: '03', value: 3 },
			{ text: 'Todos', value: 'all' },
		],
		brand: require('../../Assets/images/proyectos/logo-unbox.png'),
		cards: [
		],
	},
}
export default print