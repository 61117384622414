import React, { Component, memo, useCallback, useEffect, useRef, useState } from 'react';
import './areas.scss';
import { iconos, paginas } from './icons.js';
import Slider from 'react-slick';
import OwlCarousel from 'react-owl-carousel2';
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';
import $, { map } from 'jquery';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';
const options = {
	rewind: false,
	loop: false,
	nav: true,
	center: false,
	autoWidth: true,
	speed: 1000,
	smartSpeed: 300,
	dots: true,
	responsive: {
		0: {
			items: 1,
			autoWidth: true,
		},
		500: {
			items: 1,
			center: true,
		},
		1100: {
			items: 2,
		},
	},
};
const buttons = [
	{ value: 'sliderslider_depa_piloto', name: 'Depa piloto' },
	{ value: 'slider_areas_comunes', name: 'Áreas comunes' },
	{ value: 'slider_interiores', name: 'Interiores' },
	{ value: 'slider_exteriors', name: 'Exteriores' },
	{ value: 'url_3d', name: 'Recorrido 3D' },
	{ value: 'slider_avance', name: 'Avance de obra' },
	{ value: 'slider_end_project', name: 'Proyecto terminado' },
];
export default function Areas({ page, data }) {
	const indexActive = buttons.filter(e => {
		if (data[e.value] != false) {
			return e;
		}
	});

	const [areaActive, setAreaActive] = useState(indexActive[0]?.name);

	const activeAreas = area => {
		setAreaActive(area);
	};
	useEffect(() => {
		setAreaActive(indexActive[0]?.name);
	}, [data]);
	return (
		<div className={'areas ' + page}>
			<div className='controlador'>
				<h2 className='title'>
					<span className='bold'>DESCUBRE</span> SUS AMBIENTES
				</h2>
				<div className={`flex-row change-row `}>
					{indexActive.map((item, index) => {
						return (
							<div
								key={'btn-area-' + index}
								className={`btn btn-radio btn-project_piloto ${areaActive === item.name ? 'active' : ''}`}
								onClick={() => {
									activeAreas(item.name);
								}}
							>
								{item.name}
							</div>
						);
					})}
				</div>
				<div className='content-slider'>
					{areaActive === 'Depa piloto' && data?.sliderslider_depa_piloto.length > 0 ? <ItemArea list={data?.sliderslider_depa_piloto} name='Proyecto-terminado'></ItemArea> : null}
					{areaActive === 'Áreas comunes' ? <ItemArea list={data?.slider_areas_comunes} name='comunes'></ItemArea> : null}
					{areaActive === 'Interiores' ? <ItemArea list={data?.slider_interiores} name='Interiores'></ItemArea> : null}
					{areaActive === 'Exteriores' ? <ItemArea list={data?.slider_exteriors} name='Exteriores'></ItemArea> : null}
					{areaActive === 'Avance de obra' ? <ItemArea list={data?.slider_avance} titleAvance={data?.title_avance} name='Avance-de-obra'></ItemArea> : null}
					{areaActive === 'Proyecto terminado' ? <ItemArea list={data?.slider_end_project} name='Proyecto-terminado'></ItemArea> : null}
					{areaActive === 'Recorrido 3D' ? (
						<div className={`virtual-frame ${areaActive == 3 ? 'active' : ''}`}>
							<div className='frame'>
								<iframe frameborder='0' allowfullscreen='true' allow='vr,gyroscope,accelerometer,fullscreen' scrolling='no' src={data.url_3d}></iframe>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}

const ItemArea = ({ list, name = '', titleAvance }) => {
	const [areaActive, setAreaComun] = useState(0);
	const [areaRef, setAreRef] = useState(null);
	const iconsRef = useRef(null);
	const changeItemArea = index => {
		areaRef.current.goTo(index);
		setAreaComun(index);
	};
	const onChanged = useCallback(({ item }) => {
		setAreaComun(item.index ? item.index : 0);
	}, []);
	if (list.length == 0) {
		return null;
	}
	return (
		<>
		{
			list.length > 12 && list[0]?.icon?.url &&
			<div className='icon-signal-top'>
				<div className='btn-prev' onClick={()=>{iconsRef.current.scrollTo(0,0)}}>
					<div className='mask signal-prev'></div>
				</div>
				<div className='btn-next' onClick={()=>{iconsRef.current.scrollTo(iconsRef.current.offsetWidth+400,0)}}>
					<div className='mask signal-next'></div>
				</div>
			</div>
		}
		{
			list.length > 0 &&
			<div className='content-icons' ref={iconsRef}>
				<div className={`item-scroll ${list.length > 5 ? 'scroll' : ''}`}>
					{list.map((item, index) => {
						if (item?.icon?.url) {
							return (
								<div
									className={`icon ${areaActive == index ? 'active' : ''}`}
									key={index + name}
									onClick={e => {
										changeItemArea(index);
									}}
								>
									<div className={`mask`} style={{ WebkitMaskImage: `url(${item.icon.url})` }}></div>
								</div>
							);
						}
						return null;
					})}
				</div>
			</div>
		}
			<div className={`areas-comunes `}>
				<SliderArea list={list} onChanged={onChanged} name={'areas-comunes'} setRef={setAreRef} />
				{list[areaActive]?.icon?.url ? (
					<div class='info-slider'>
						<div class='sky'>
							<div class={`mask icon`} style={{ WebkitMaskImage: `url(${list[areaActive]?.icon.url})`, maskImage: `url(${list[areaActive]?.icon.url})` }}></div>
							<span class='title'>{list[areaActive]?.title || ''}</span>
						</div>
						<span class='paragraph'>{list[areaActive]?.description || ''}</span>
					</div>
				) : null}
				{titleAvance ? (
					<div class='info-slider avance'>
						<div class='sky'>
							<div class={`mask icon excavadora`}></div>
							<span class='title'>{titleAvance}</span>
						</div>
					</div>
				) : null}
			</div>
		</>
	);
};

const SliderArea = memo(({ list, onChanged, name, setRef }) => {
	const ref = useRef();
	useEffect(() => {
		ref.current.goTo(0);
		setRef(ref);
	}, []);
	return (
		<>
			<OwlCarousel ref={ref} options={options} events={{ onChanged }} className='owl-slider'>
				{list?.map((item, index) => {
					return (
						<div className='slider' data-src={item?.image.url} href={item?.image.url} key={index + name}>
							<picture className='expand-img'>
								<source media='(min-width: 650px)' srcset={item?.image.url}></source>
								<LightgalleryProvider key={name + index}>
									<LightgalleryItem key={index} group='any' src={item?.image.url}>
										<img src={item?.image.url} style={{ objectPosition: `${item?.position ? item.position : ''}` }} alt={item.title}></img>
									</LightgalleryItem>
								</LightgalleryProvider>
							</picture>
						</div>
					);
				})}
			</OwlCarousel>
			<div className='icon-signal'>
				<div className='btn-prev' onClick={() => ref.current.prev()}>
					<div className='mask signal-prev'></div>
				</div>
				<div className='btn-next' onClick={() => ref.current.next()}>
					<div className='mask signal-next'></div>
				</div>
			</div>
		</>
	);
});
