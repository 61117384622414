import { useState } from 'react'
import './style.scss'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { useEffect } from 'react';


export default function PagePostventa(){
    const [Value, setValue] = useState("Elige un proyecto");
    const [results, setResult] = useState([
        {project:"Seleciona un proyecto",value:"",url:""},
        {project:"Midway",value:"Midway",district:"Magdalena",url:"https://casaideal.sperant.com/portal/sign_in"},
        {project:"Print",value:"Print",district:"Chorrillos",url:"https://casaideal.sperant.com/portal/sign_in"},
        {project:"Town",value:"Town",district:"Cercado de Lima",url:"https://casaideal.sperant.com/portal/sign_in"},
        {project:"Trend",value:"Trend",district:"Centro de Lima",url:"https://casaideal.sperant.com/portal/sign_in"},
        {project:"Stay",value:"Stay",district:"Jesús María",url:"https://casaideal.sperant.com/portal/sign_in"},
        {project:"Unbox",value:"Unbox",district:"Barranco",url:"https://casaideal.sperant.com/portal/sign_in"},
        {project:"Unity",value:"Unity",district:"Cercado de Lima",url:"https://casaideal.sperant.com/portal/sign_in"},
        
        {project:"Nou",value:"Nou",district:"Pueblo Libre",url:"https://casaideal.sperant.com/portal/sign_in"},
        {project:"Almudena",value:"Almudena",district:"Jesús María",url:"/postventa/Almudena"},
        {project:"Allegro",value:"Allegro",district:"San Borja",url:"/postventa/Allegro"},
        {project:"Alto Mirador",value:"Alto-Mirador",district:"Pueblo Libre",url:"/postventa/Alto-Mirador"},
        {project:"Alto Mirador 2",value:"Alto-Mirador-2",district:"Jesús María",url:"/postventa/Alto-Mirador-2"},
        {project:"Arona",value:"Arona",district:"San Miguel",url:"/postventa/Arona"},
        {project:"Bilbao",value:"Bilbao",district:"Magdalena del Mar",url:"/postventa/Bilbao"},
        {project:"Castilla",value:"Castilla",district:"Lince",url:"/postventa/Castilla"},
        {project:"Los Patriotas",value:"Los Patriotas",district:"San Miguel",url:"/postventa/Los-Patriotas"},
        {project:"Hermano Lobo",value:"Hermano-Lobo",district:"San Borja",url:"/postventa/Hermano-Lobo"},
        {project:"MSalaverry",value:"MSalaverry",district:"Jesús María",url:"/postventa/MSalaverry"},
        {project:"Paseo del Parque",value:"Paseo-del-Parque",district:"Chorrillos",url:"/postventa/Paseo-del-Parque"},
        {project:"Parque Sol",value:"Parque-Sol",district:"Jesús María",url:"/postventa/Parque-Sol"},
        {project:"Sevilla",value:"Sevilla",district:"Surco",url:"/postventa/Sevilla"},
        {project:"Terrazul",value:"Terrazul",district:"Surquillo",url:"/postventa/proyectos/Terrazul"},
        
    ])
    const [Link, setLink] = useState("");

    const openLink = (url) => {
        window.location.href = Link;
    }

    const Search = (value) => {
        try {
            let link = results.filter((filter)=>{if(filter.value == value){return filter.url}});
            setLink(link[0].url);
            console.log(link[0].url)
        } catch (error) {
            
        }
    }

    const handleChange = (event) => {
        // console.log(event.target.value)
        Search(event.target.value);
        setValue(event.target.value);
    }
    useEffect(()=>{
        setValue("Elige un proyecto");
    },[])
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
    };
    return(
        <div className="benefits page-post-venta">
            <section>
                <div className="SliderDepartamentos">
                    <div className='background d-flex'>
                        <picture>
                            <source className="background " media="(min-width: 740px)" srcset={require("../../Assets/images/beneficios/background-beneficios.png")}></source> 
                            <img className='background' src={require("../../Assets/images/beneficios/background-beneficios-movil.png")}></img>
                        </picture>
                    </div>
                    <div className='content-title-page-post-venta'>
                        <span className="title-background">Servicio Post Venta</span>
                    </div>
                </div>
            </section>
            <section className='select-search container'>
                <span className='title-select-search'></span>
                <p className='paragraph text-center'>Estamos comprometidos con la atención de calidad antes y después de la entrega de tu departamento.<br/>Antes de enviarnos tus dudas o consultas elige un proyecto.</p>

                <div className='content-select-search'>
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Elige un proyecto</InputLabel>

                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        // value={Value}
                        onChange={handleChange}
                        displayEmpty
                        // label="Elige un proyecto"
                        MenuProps={MenuProps}
                    >
                        {
                            results.map((item, index)=>{
                                return(
                                    <MenuItem value={item.value} key={index}>
                                        <div>{item.project} - {item.district}</div>
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                    {/* <select className='select' onChange={(e)=>Search(e)}>
                        <option value={''} disabled selected>Seleciona un proyecto</option>
                        
                    </select> */}
                    <button className='btn' onClick={()=>{openLink()}}>
                        Elegir
                    </button>
                </div>
            </section>
        </div>
    )
}

