import React, { Component, useEffect, useState } from 'react';
import Contacto from '../../components/contacto/contacto';
import ContactoPromo from '../../components/contacto/contactoPromo';
import './terminos.scss';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { HOST } from '../../data/config';
import FormularioReferidos from '../../components/contacto/formularioReferidos';

export const Terminos = () => {
    const params = useParams();
    const [benefit, setBenefit] = useState({});

    const replaceSpecialChars = (str) => {
        return str.replace(/ñ/g, 'n');
    }
    const convertKbtoMb = (str) => {
        const kb = parseFloat(str);
        return parseInt(kb / 1024);
    }
	const fetching = async props => {
		const response = await fetch(HOST + '/wp-json/custom-api/v1/benefit?name=' + replaceSpecialChars(params?.beneficio));
		const data = await response.json();
		console.log(data);
        setBenefit(data);
	};
	useEffect(() => {
		fetching();
	}, []);
    
	return (
		<div className='page-terminos'>
			<section>
				<div className='SliderDepartamentos'>
					<div className='background d-flex'>
						<picture>
							<source className='background ' media='(min-width: 740px)' srcset={benefit?.imagen_banner?.url}></source>
							<img className='background' src={benefit?.imagen_miniatura?.url}></img>
						</picture>
					</div>
					<span className='title-background' dangerouslySetInnerHTML={{ __html: params.beneficio.replace(/-/g, ' ') }}></span>
				</div>
			</section>
			<section className='information container'>
				<div className='info'>
					<span className='handing-over bold'>Descripción</span>
					<div className='content-paragraph' dangerouslySetInnerHTML={{ __html: benefit?.description }}></div>
					<a href={benefit?.pdf?.url} target='_blank' className='btn-download outline-gray'>
						<div className='icon-button'>
							<img className='icon-download' src={require('../../Assets/iconos/icon-download.svg').default}></img>
						</div>
						<div className='text-button'>
							Términos y condiciones
							<span className='peso'>{convertKbtoMb(benefit?.pdf?.filesize)} kb</span>
						</div>
					</a>
					{benefit?.list_products && !!benefit?.list_products.length ? (
						<div className='content-all'>
                            {
                                benefit?.list_products.map((item)=>{
                                    return (<div className='content-list'>
                                        <h2 className='title-list'>{item.titulo}</h2>
                                        <div className='table'>
                                            <div className='header-table'>
                                                <div className='name-product'>Producto</div>
                                                <div className='cant-product'>Cantidad</div>
                                            </div>
                                            <div className='body-table'>
                                                {item.list.map(e => {
                                                    return (
                                                        <div className='line-product'>
                                                            <div className='name-product'>
                                                                <img className='img-product' src={e.image.url}></img>
                                                                {e.title}
                                                            </div>
                                                            <div className='cant-product'>{e.cant}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>)
                                })
                            }
						</div>
					) : null}
				</div>
				{
					benefit?.name === 'Campaña de referidos' ? 	<div className="form" id="formulario">
					<FormularioReferidos
						page={"benefits"}
						promo={benefit?.name}
						options={benefit?.projects_relations?.map((e)=>{return {text:e}}) ?? []}
					></FormularioReferidos>
					</div> :
					<div className='form' id='formulario'>
						<ContactoPromo page={'benefits'} promo={benefit?.name} options={benefit?.projects_relations?.map((e)=>{return {text:e}}) ?? []}></ContactoPromo>
					</div>  
				
				}
			</section>
		</div>
	);
};
