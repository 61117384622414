import React, { Component } from 'react'
import Lottie from 'react-lottie';
var cont = 0;
export default class Icon extends Component {
    constructor(props){
        super(props)
        this.stopAnimation = this.stopAnimation.bind()
        this.state = {
            isStopped: false,
            hide:false,
            isPaused: false,
            defaultOptions:{
                loop: true,
                autoplay: true,
                speed: 10000,
                animationData: this.props.icon,
                rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                },
            },
            eventListeners:[
                {
                  eventName: 'loopComplete',
                  callback: this.stopAnimation,
                },
            ]
        }
    }
    stopAnimation =()=>{
        if(cont == 1){
            this.setState({isStopped:true})
            this.props.event()
        }else{
            cont++
        }
    }
    addItem(){
        this.setState({isPaused:false,isStopped:false})
        let time = setInterval(() => {
            this.setState({isPaused:true,isStopped:true})
            clearInterval(time)
        }, 1000);
    }
    render(){
        return(
            <div className="lottie-icon">
                <Lottie    
                    options={this.state.defaultOptions}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}
                    speed={.8}
                    eventListeners={this.state.eventListeners}
                >
                </Lottie>
            </div>
        )
    }
}
