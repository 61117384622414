import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    selected:[{name:"not defined",construction:{total_area:0},units:[{name:'default'}]}]
}

export const CalculatorStore = createSlice({
    initialState,
    name:'calculator',
    reducers:{
        updateSelected:(state,value)=>{
            state.selected = value.payload
        }
    }
});
export const { updateSelected } = CalculatorStore.actions

export default CalculatorStore.reducer