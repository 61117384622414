import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeMessageSelect,hidePopupSelect} from '../../data/carts';
 class Popupselect extends Component {
    constructor(props){
        super(props)
    }
    
    render() {
        return (
        <dialog className={`dialog-select  ${this.props.value.activePopupSelect == true ? "active" : ""}`} data-type={"modal-cart"}
            onClick={(e)=>{this.props.hidePopupSelect(e.target.dataset.type)}}
            >
            <div className={`popup-select ${this.props.value.listProjects.length > 1 ? 'active' : 'active'} ${this.props.value.popupMessage == true ? "" : 'inactive'}`}>
                <div className='close pointer' onClick={(e)=>{this.props.hidePopupSelect('modal-cart')}} data-type={"modal-cart"}>
                    <div className="icon-close"></div>
                </div>
                Selecciona un piso.
            </div>
        </dialog>
        );
    }
}
const MapStateProps = (state)=>{
    return(
        {
            value:state.cartStore
        }
    )
}
export default connect(MapStateProps,{ closeMessageSelect,hidePopupSelect})(Popupselect)