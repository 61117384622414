 import './politicas.scss'
export const Politics = () => {
    return <div className='Politics'>
        <section>
                    <div className="SliderDepartamentos">
                        <div className='background d-flex'>
                            <picture>
                                <source className="background " media="(min-width: 740px)" srcset={require("../../Assets/images/beneficios/politica-de-privacidad.jpg")}></source> 
                                <img className='background' src={require("../../Assets/images/beneficios/politica-de-privacidad.jpg")}></img>
                            </picture>
                        </div>
                        <span className="title-background">Politicas de privacidad</span>
                    </div>
                </section>
        <div className="content-politicas container">
                    <div className="content-scroll">
                        <p className="paragraph">
                            Las presentes “Políticas de Privacidad” se aplicarán a todos los bancos de datos personales que
                            sean objeto de tratamiento por parte de <span className="bold">Casaideal Inmobiliaria.</span>
                        </p>
                        <p className="paragraph">
                            Velamos por la protección y privacidad de los datos personales de nuestros clientes y prospecto
                            de clientes. Por ello, garantizamos la absoluta confidencialidad y seguridad de los mismos
                            conforme a lo establecido en la Ley de Protección de Datos Personales – Ley N° 29733 y su
                            Reglamento aprobado por el Decreto Supremo N° 003-2013-JUS.
                        </p>
                        <span className="sub-title">
                            Objetivo
                        </span>
                        <p className="paragraph">
                            El objetivo de la presente Política de Privacidad es dar a conocer la manera en que se recogen,
                            tratan y protegen los datos personales a los que accedemos en el ejercicio de nuestras actividades
                            comerciales. La Política Interna de Protección de Datos Personales constituye un documento que
                            garantiza la observancia de las normas que regulan el tratamiento y la protección de datos
                            personales, así como el respeto de los derechos que asisten a los Titulares de Datos Personales
                            con quienes  <span className="bold">Casaideal Inmobiliaria</span> tenga vinculación. En tal sentido, constituye una política
                            interna para el cumplimiento de las disposiciones contenidas en la Ley 29733, ley de Protección
                            de Datos Personales y su Reglamento, el Decreto Supremo No 003-2013-JUS.
                        </p>
                        <span className="sub-title">
                            Principios de la Protección de Datos
                        </span>
                        <p className="paragraph">
                            Esta política se regirá bajo los siguientes principios rectores establecidos en el artículo 4 de la Ley
                            Nº 29733 – Ley de Protección de Datos Personales y se han de aplicar, de manera armónica e
                            integral, en el desarrollo, interpretación y aplicación de la Ley:
                        </p>
                        <div className="list">
                            <p className="item">
                                <div className="line">
                                    -
                                </div>
                                <div className="separe">
                                <span className="bold">Principio de Legalidad:</span> Rechazamos la recopilación de los datos personales de nuestros
                                clientes y prospecto de clientes por medios fraudulentos, desleales o ilícitos.
                                </div>
                            </p>
                            <p className="item">
                                <div className="line">
                                    -
                                </div>
                                <div className="separe">
                                <span className="bold">Principio de Consentimiento:</span> Para el tratamiento de los datos personales de nuestros
                                clientes y prospecto de clientes debe mediar su consentimiento.
                                </div>
                            </p>
                            <p className="item">
                                <div className="line">
                                    -
                                </div>
                                <div className="separe">
                                <span className="bold">Principio de Finalidad:</span> Los datos personales deben ser recopilados para una finalidad
                                determinada, explícita y lícita, y su tratamiento no debe extenderse a otra finalidad
                                distinta a aquella para la cual fueron recopilados
                                </div>
                            </p>
                            <p className="item">
                                <div className="line">
                                    -
                                </div>
                                <div className="separe">
                                <span className="bold">Principio de Proporcionalidad:</span> Todo tratamiento de datos personales será adecuado,
                                    relevante y no excesivo a la finalidad para la que estos hubiesen sido recopilados.
                                </div>
                            </p>
                            <p className="item">
                                <div className="line">
                                    -
                                </div>
                                <div className="separe">
                                <span className="bold">Principio de Calidad:</span> Los datos personales que vayan a ser tratados serán veraces,
                                exactos y, en la medida de lo posible, actualizados, necesarios, pertinentes y adecuados
                                respecto de la finalidad para la que fueron recopiladas.
                                </div>
                            </p>
                            <p className="item">
                                <div className="line">
                                    -
                                </div>
                                <div className="separe">
                                <span className="bold">Principio de Seguridad:</span> Se adoptan las medidas técnicas, organizativas y legales
                                necesarias para garantizar la seguridad de los datos personales
                                </div>
                            </p>
                            <p className="item">
                                <div className="line">
                                    -
                                </div>
                                <div className="separe">
                                <span className="bold">Principio de Disposición de Recurso:</span> Informamos a nuestros clientes, prospecto de
                                clientes y trabajadores, proveedores, entre otros que pueden ejercer sus derechos
                                contenidos en el derecho constitucional a la protección de datos personales en sede
                                administrativa ante la Autoridad Nacional de Protección de Datos y en sede jurisdiccional
                                ante el Poder Judicial a los efectos del inicio del correspondiente proceso de habeas data.

                                </div>
                            </p>
                            <p className="item">
                                <div className="line">
                                    -
                                </div>
                                <div className="separe">
                                <span className="bold">Principio de Nivel de Protección Adecuado:</span> : Garantizamos el nivel adecuado de
                                protección de los datos personales para el flujo transfronterizo de datos personales, con
                                un mínimo de protección equiparable a lo previsto por la Ley Nº 29733 o por los 
                                estándares internacionales de la materia. Por ello, solicitamos el consentimiento previo,
                                expreso e informado a los titulares de los datos personales.

                                </div>
                            </p>
                            
                        </div>

                        <span className="sub-title mt-1">
                            Finalidad
                        </span>
                        <p className="paragraph">
                        <span className="bold">Casaideal Inmobiliaria</span> tratará los datos personales de clientes y prospecto de clientes con la
                        finalidad de:

                        </p>
                        <p className="item">
                            <div className="line bold">
                            •
                            </div>
                            <div className="separe">
                            Enviar a los clientes y prospecto de clientes información de ofertas y/o promociones de
                            productos y/o servicios ofrecidos por <span className="bold">Casaideal Inmobiliaria</span>, así como efectuar acciones de
                            publicidad y prospección comercial.

                            </div>
                        </p>
                        <p className="item">
                            <div className="line bold">
                            •
                            </div>
                            <div className="separe">
                            Remitir vía electrónica (correo electrónico o cualquier otro medio electrónico o equivalente) o
                            telefónica (Whatsapp, SMS o llamada telefónica) información de ofertas y/o promociones de
                            productos.

                            </div>
                        </p>
                        <p className="item">
                            <div className="line bold">
                            •
                                
                            </div>
                            <div className="separe">
                            Almacenar y tratar los Datos Personales con fines estadísticos y/o históricos, así como analizar
                                historiales de compra y elaborar perfiles de compra.
                            </div>
                        </p>
                        <span className="sub-title mt-1">
                            Tratamiento de datos personales
                        </span>
                        <p className="paragraph">
                        Los datos personales facilitados se almacenarán en los bancos de datos que forman parte del
                        sistema de información de <span className="bold">Casaideal Inmobiliaria</span> y serán tratados para poder llevar a cabo las
                        finalidades expuestas anteriormente. Los datos personales almacenados sólo podrán ser
                        conocidos y manejados por el personal de <span className="bold">Casaideal Inmobiliaria</span>, que necesite conocer dicha
                        información para poder contestar las solicitudes del titular de los datos personales. Estos datos
                        personales serán tratados de forma leal y lícita y no serán utilizados para otras finalidades
                        incompatibles con las especificadas.
                        </p>
                        <span className="sub-title">
                            Ejercicio de derechos
                        </span>
                        <p className="paragraph">
                            Puedes solicitar que atendamos tus derechos de acceso, información, rectificación, cancelación,
                            oposición y revocación al uso de tus datos personales. Para ello, puedes dirigirte por vía
                            electrónica escribiendo a: <a href="mailto:sac@casaideal.com.pe">sac@casaideal.com.pe</a>
                        </p>
                        <span className="sub-title">
                            Vigencia y modificación de la presente Política de Privacidad
                        </span>
                        <p className="paragraph">
                        La Política de privacidad Web de <span className="bold">Casaideal Inmobiliaria</span> ha sido actualizada el mes de Junio
                        2021. <span className="bold">Casaideal Inmobiliaria</span>, se reserva el derecho a modificar su Política de Privacidad Web en
                        el supuesto de que exista un cambio en la legislación vigente, doctrinal, jurisprudencial o por
                        criterios propios empresariales. Si se introdujera algún cambio en esta Política, el nuevo texto se
                        publicará en esta misma Web.
                        </p>
                    </div>
                </div>
    </div>
}