import { Link } from 'react-router-dom';
import './404.scss';
export const Error404 = () => {
    return(
        <div className='error404'>
            <meta name="robots" content="noindex"/>
            <img className='tramado-1 desktop' src={require('../../Assets/iconos/thanks-icon-left-top.svg').default} ></img>
            <img className='tramado-2 desktop' src={require('../../Assets/iconos/thanks-icon-bottom-right.svg').default} ></img>
            <img className='tramado-3' src={require('../../Assets/iconos/bottom-icon.svg').default} ></img>
            <div className='container'>
                <div className='icon-error'>
                <span className='title bold'>ERROR</span>
                <span className='sub-title bold'>404</span>
                </div>
                <p className='paragraph'>¡Parece que no encontramos esta página!</p>
                <Link to="/" className='button'>
                    Ir a Inicio
                </Link>
            </div>
        </div>
    )
}