export const properties = 
    { 
        nou:{
            sperant_id: 16,
            route:"/nou",
            areas:{
                comunes:{
                    desktop:[
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-lobby.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-parrilla.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-bar.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-jardin.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-sala.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-sala2.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-salachild.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-salajuegos.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-yoga.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-relax-zone.jpg"),},
                    ],
                    movil:[
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-lobby.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-parrilla.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-bar.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-jardin.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-sala.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-sala2.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-salachild.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-salajuegos.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-yoga.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/areas_comunes/desktop/c-relax-zone.jpg"),},
                    ],
                },
                interiores:{
                    desktop:[
                        {img:require("./Assets/images/Slider/brasil/interiores/desktop/Cocina_1.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/interiores/desktop/Dormitorio-principal.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/interiores/desktop/Dormitorio-secundario.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/interiores/desktop/Sala-comedor.jpg"),}
            
                    ],
                    movil:[
                        {img:require("./Assets/images/Slider/brasil/interiores/movil/Cocina_1.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/interiores/movil/Dormitorio-principal.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/interiores/movil/Dormitorio-secundario.jpg"),},
                        {img:require("./Assets/images/Slider/brasil/interiores/movil/Sala-comedor.jpg"),}
                    ],
                 },
                avance:{
                    cantButton:4,
                    title:"Avance de obra Marzo",
                    desktop:[
                        {img:require("./Assets/images/Slider/brasil/avance-de-obra/1.png")},
                        {img:require("./Assets/images/Slider/brasil/avance-de-obra/2.png")},
                        {img:require("./Assets/images/Slider/brasil/avance-de-obra/3.png")},
                        {img:require("./Assets/images/Slider/brasil/avance-de-obra/4.png")},
                        {img:require("./Assets/images/Slider/brasil/avance-de-obra/5.png")},
                        {img:require("./Assets/images/Slider/brasil/avance-de-obra/6.png")},
                    ]
                },
                 recorrido:{active:"",link:"https://kuula.co/share/collection/7lXvY?fs=1&vr=0&sd=1&thumbs=1&chromeless=0&logo=0"}
            },
            info:{
                title:"VERSÁTIL",subtitle:"& PERFECTO",
                pisos:"22",depas:"137",dorms:`<span class="info-attr">1 dorm plus,</span><span class="info-attr"> 2 dorms y</span><span class="info-attr"> 2 dorms plus.</span>`, ubicacion:"Av. Brasil N° 2790, Pueblo Libre",precio:"S/ 326,000",
                movil:{pisos:"22 pisos y 137 depas ",dorms:"1 dorm plus, 2 dorms., 2 dorms plus.",precio:"S/ 326,000",ubicacion1:"Av. Brasil N° 2790, ",ubicacion2:"Pueblo Libre"},
                entrega:'Construcción',
                entregamovil:'En construcción',
                fachada:require("./Assets/images/fachada/nou/fachadaNou.jpg"),
                ingreso:require("./Assets/images/fachada/nou/ingreso.jpg"),
                parrafo1:'NOU es la opción ideal para disfrutar tu propio espacio rodeado de todas las comodidades que esperabas.',
                parrafo2:'Descubre los beneficios de este moderno concepto que intrega versátiles áreas sociales diseñadas para tu estilo de vida y la de tu familia, junto a departamentos con gran iluminación natural.',
                parrafoArquitectura1:'Una fachada icónica con elegancia en cada detalle.<div class="span"></div>Ubicado en una esquina para potenciar la iluminación natural de cada ambiente.',
                brochure:'/brochure/Brochure-nou.pdf',
                arquitectura:'<span class="bold">ARQUITECTURA </span> & DISEÑO',
            },
            form:[
                {value:"1+",title:"1 Dormitorio plus"},
                {value:2,title:"2 Dormitorios"},
                {value:"2+",title:"2 Dormitorios plus"}
            ],
            slideDepa:{
                desktop:require("./Assets/sliderdepa/desktop/nou-desktop.jpg"),
                movil:require("./Assets/sliderdepa/movil/nou-movil.jpg"),                
            },
        },
        salaverry:{
            sperant_id: 7,
            route:"/salaverry",
            areas:{
                comunes:{
                    desktop:[
                        {img:require("./Assets/images/Slider/salaverry/comunes/desktop/Lobby.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/comunes/desktop/Sky-Bar.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/comunes/desktop/Parrilla.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/comunes/desktop/Gym.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/comunes/desktop/Chill.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/comunes/desktop/Gamer.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/comunes/desktop/Terraza.jpg")},
                    ],
                    movil:[
                        {img:require("./Assets/images/Slider/salaverry/comunes/movil/Lobby.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/comunes/movil/Sky-Bar.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/comunes/movil/Parrilla.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/comunes/movil/Gym.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/comunes/movil/Chill.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/comunes/movil/Gamer.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/comunes/movil/Terraza.jpg")},
                    ],
                },
                interiores:{
                    desktop:[
                        {img:require("./Assets/images/Slider/salaverry/interiores/desktop/s-comedor.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/interiores/desktop/Cocina.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/interiores/desktop/Dormitorio-Principal.jpg")},
                    ],
                    movil:[
                        {img:require("./Assets/images/Slider/salaverry/interiores/movil/s-comedor.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/interiores/movil/Cocina.jpg")},
                        {img:require("./Assets/images/Slider/salaverry/interiores/movil/Dormitorio-Principal.jpg")},
                    ],
                 },
                avance:{
                    cantButton:"avance-none",
                    title:"Avance de obra Agosto",
                    desktop:[
                      
                    ]
                },
                recorrido:{active:"ocultar",link:""},
            },
            form:[
                {value:3,title:"3 Dormitorios"}
            ],
            info:{
                title:"MILLENNIAL",subtitle:"LIVING",
                entrega:'Entrega inmediata',
                entregamovil:'Entrega inmediata',
                pisos:"22",depas:"77",dorms:"1 y 3 Dorms",ubicacion:"Av. Salaverry 2124 - Jesús María",precio:"S/ 299,000",
                movil:{pisos:"22 pisos y 77 depas ",dorms:"1 y 3 Dormitorios.",precio:"S/ 299,000",ubicacion1:"Av. Salaverry 2124",ubicacion2:"Jesús María"},
                fachada:require("./Assets/images/fachada/salaverry/fachadaSalaverry.jpg"),
                ingreso:require("./Assets/images/fachada/salaverry/ingreso.jpg"),
                parrafo1:'Es una forma de vivir que sucede en las ciudades, espacios cuidados donde la conexión wifi es tan rápida como la avenida que te conecta al trabajo o a donde quieras llegar.',
                parrafo2:'Un estilo de vida que cambiará tu forma de vivir, pensar y actuar. Dale un nuevo ritmo a tu vida.',
                parrafoArquitectura1:'Cada detalle del proyecto contribuye al <span class="bold">ahorro de agua y energía.</span><div class="span"></div><span class="bold">Mejor distribución</span> de los espacios y mayor ingreso de <span class="bold">luz natural.</span>',
                brochure:'/brochure/Brochure-salaverry.pdf',
                arquitectura:'<span class="bold">ARQUITECTURA </span> & DISEÑO',
            },
        },

        town:{
            sperant_id: 17,
            route:"/town",
            areas:{
                comunes:{
                    desktop:[
                        {img:require("./Assets/images/Slider/town/comunes/desktop/lobby.jpg")},
                        // {img:require("./Assets/images/Slider/town/interiores/desktop/SUM.jpg")},
                        {img:require("./Assets/images/Slider/town/comunes/desktop/Jardin.jpg")},
                        {img:require("./Assets/images/Slider/town/comunes/desktop/parrilla.jpg")},
                        {img:require("./Assets/images/Slider/town/comunes/desktop/parrilla-terraza.jpg")},
                        {img:require("./Assets/images/Slider/town/comunes/desktop/chill.jpg")},
                    ],
                    movil:[
                        {img:require("./Assets/images/Slider/town/comunes/movil/lobby.jpg")},
                        {img:require("./Assets/images/Slider/town/interiores/movil/dormitorio.jpg")},
                        {img:require("./Assets/images/Slider/town/comunes/movil/Jardin.jpg")},
                        {img:require("./Assets/images/Slider/town/comunes/movil/parrilla.jpg")},
                        {img:require("./Assets/images/Slider/town/comunes/movil/parrilla-terraza.jpg")},
                        {img:require("./Assets/images/Slider/town/comunes/movil/chill.jpg")},
                    ],
                },
                interiores:{
                    desktop:[
                        // {img:require("./Assets/images/Slider/town/interiores/desktop/Cocina_2.jpg")},
                        // {img:require("./Assets/images/Slider/town/comunes/desktop/sala_comedor.jpg")},
                        {img:require("./Assets/images/Slider/town/interiores/desktop/dormitorio.jpg")},
                        
                    ],
                    movil:[
                        {img:require("./Assets/images/Slider/town/interiores/movil/Cocina_2.jpg")},
                        // {img:require("./Assets/images/Slider/town/comunes/movil/sala_comedor.jpg")},
                        {img:require("./Assets/images/Slider/town/interiores/desktop/dormitorio.jpg")},
                    ],
                 },
                 avance:{
                    cantButton:4,
                    title:"Avance de obra Febrero",
                    desktop:[
                        {img:require("./Assets/images/Slider/town/avance-de-obra/imagen-1.jpeg")},
                        {img:require("./Assets/images/Slider/town/avance-de-obra/imagen-2.jpeg")},
                        {img:require("./Assets/images/Slider/town/avance-de-obra/imagen-3.jpeg")},
                    ]
                },
                 recorrido:{active:"",link:"https://kuula.co/share/collection/7lX4n?fs=1&vr=0&sd=1&thumbs=1&chromeless=0&logo=0"}

            },
            form:[
                {value:2,title:"2 Dormitorios"},
                {value:3,title:"3 Dormitorios"}
            ],
            info:{
                title:"CERCA DE ",subtitle:"TODO",
                entrega:'Construcción',
                entregamovil:'En construcción',
                pisos:"17",depas:"64",dorms:" 2 y 3 Dorms.",ubicacion:"Av. Petit Thouars N° 1281, Santa Beatriz ",precio:"S/ 311,200",
                movil:{pisos:"17 pisos y 64 epas ",dorms:"1, 2 y 3 Dormitorios.",precio:"S/ 311,200",ubicacion1:"Av. Petit Thouars N° 1281, ",ubicacion2:"Santa Beatriz"},
                fachada:require("./Assets/images/fachada/Slide/TOWN.jpg"),
                ingreso:require("./Assets/images/fachada/town/ingreso.jpg"),
                parrafo1:'En TOWN estás cerca de todo lo que necesitas y conectado al resto de la ciudad por sus avenidas principales.',
                parrafo2:'Además de sus excelentes áreas sociales disfruta de los parques, restaurantes, camina por tu barrio y aprovecha todos los servicios que esta zona te ofrece.',
                parrafoArquitectura1:'Una fachada contemporánea y atemporal.  <div class="span"></div>Con una distribución exclusiva en el interior albergando hasta 4 departamentos en cada piso.',
                brochure:'/brochure/Brochure-town.pdf',
                arquitectura:'<span class="bold">ARQUITECTURA </span> & DISEÑO',
            },
            slideDepa:{
                desktop:require("./Assets/sliderdepa/desktop/town-desktop.jpg"),
                movil:require("./Assets/sliderdepa/movil/town-movil.jpg"),                
            },
        },

        trend:{
            sperant_id: 18,
            route:"/trend",
            areas:{
                comunes:{
                    desktop:[
                        {img:require("./Assets/images/Slider/trend/comunes/desktop/lobby.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/desktop/area-de-padres.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/desktop/area-chill.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/desktop/relax-zone.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/desktop/skybar.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/desktop/terraza-grill.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/desktop/Kids-Room.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/desktop/Yoga-y-bienestar.jpg")},
                    ],
                    movil:[
                        {img:require("./Assets/images/Slider/trend/comunes/movil/lobby.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/movil/area-de-padres.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/movil/area-chill.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/movil/relax-zone.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/movil/skybar.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/movil/terraza-grill.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/movil/Kids-Room.jpg")},
                        {img:require("./Assets/images/Slider/trend/comunes/movil/Yoga-y-bienestar.jpg")},
                    ],
                },
                interiores:{
                    desktop:[
                        {img:require("./Assets/images/Slider/trend/interiores/desktop/cocina.jpg")},
                        {img:require("./Assets/images/Slider/trend/interiores/desktop/dormitorio-principal.jpg")},
                        {img:require("./Assets/images/Slider/trend/interiores/desktop/dormitorio-nina.jpg")},
                        {img:require("./Assets/images/Slider/trend/interiores/desktop/dormitorio-niño.jpg")},
                        {img:require("./Assets/images/Slider/trend/interiores/desktop/sala-comedor.jpg")},

                    ],
                    movil:[
                        {img:require("./Assets/images/Slider/trend/interiores/movil/cocina.jpg")},
                        {img:require("./Assets/images/Slider/trend/interiores/movil/dormitorio-principal.jpg")},
                        {img:require("./Assets/images/Slider/trend/interiores/movil/dormitorio-nina.jpg")},
                        {img:require("./Assets/images/Slider/trend/interiores/movil/dormitorio-niño.jpg")},
                        {img:require("./Assets/images/Slider/trend/interiores/movil/sala-comedor.jpg")},
                    ],
                },
                avance:{
                    cantButton:"avance-none",
                    title:"Avance de obra Agosto",
                    desktop:[

                    ]
                },
                 recorrido:{active:"",link:"https://kuula.co/share/7FDd6/collection/7lZ3H"}

            },
            form:[
                {value:2,title:"2 Dormitorios"},
                {value:3,title:"3 Dormitorios"}
            ],
            info:{
                title:"CREA TU PROPIA",subtitle:"HISTORIA",
                entrega:'En construcción',
                entregamovil:'En construcción',
                pisos:"22",depas:"84",dorms:"2 y 3 Dorms.",ubicacion:"Av. Mariano Cornejo N° 1848, Cercado de Lima",precio:"S/ 309,000",
                movil:{pisos:"22 pisos y 84 depas ",dorms:"2 y 3 Dormitorios.",precio:"S/309,000",ubicacion1:"Av. Mariano Cornejo N° 1848, ",ubicacion2:"Cercado de Lima"},
                fachada:require("./Assets/images/fachada/trend/fachadaTrend.jpg"),
                ingreso:require("./Assets/images/fachada/trend/ingreso.jpg"),
                parrafo1:'Hay una historia que compartimos y una historia personal. Una vida en común, y una vida propia: La de tu familia, la de tus hijos y la de sus estudios. La de su futuro. ',
                parrafo2:'Trend está a un paso de todo lo que tu familia necesita: Educación, cultura y espacios verdes.',
                parrafoArquitectura1:'Un concepto vanguardista que se refleja en cada uno de sus espacios.<div class="span"></div>Interiores con los mejores acabados en la zona.',
                brochure:'/brochure/Brochure-trend.pdf',
                arquitectura:'<span class="bold">ARQUITECTURA </span> & DISEÑO',
            },
            slideDepa:{
                desktop:require("./Assets/sliderdepa/desktop/trend-desktop.jpg"),
                movil:require("./Assets/sliderdepa/movil/trend-movil.jpg"),                
            },
        },
        unity:{
            sperant_id: 20,
            route:"/unity",
            areas:{
                comunes:{
                    desktop:[
                        {img:require("./Assets/images/Slider/unity/comunes/Lobby.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/Relax-zone.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/terraza-grill1.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/terraza-grill2.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/yoga-y-bienestar.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/coworking.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/area-chill.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/Centro-de-lavado.jpg")},
                    ],
                    movil:[
                        {img:require("./Assets/images/Slider/unity/comunes/Lobby.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/Relax-zone.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/terraza-grill1.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/terraza-grill2.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/yoga-y-bienestar.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/coworking.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/area-chill.jpg")},
                        {img:require("./Assets/images/Slider/unity/comunes/Centro-de-lavado.jpg")},
                    ],
                },
                interiores:{
                    desktop:[
                        {img:require("./Assets/images/Slider/unity/interiores/sala.jpg")},
                        {img:require("./Assets/images/Slider/unity/interiores/sala-de-estar.jpg")},
                        {img:require("./Assets/images/Slider/unity/interiores/dormitorio-principal.jpg")},
                        {img:require("./Assets/images/Slider/unity/interiores/cocina.jpg")},
                        // {img:require("./Assets/images/Slider/unity/interiores/bano-secundario.jpg")},
                        {img:require("./Assets/images/Slider/unity/interiores/bano-principal.jpg")},
                    ],
                    movil:[
                        {img:require("./Assets/images/Slider/unity/interiores/sala.jpg")},
                        {img:require("./Assets/images/Slider/unity/interiores/sala-de-estar.jpg")},
                        {img:require("./Assets/images/Slider/unity/interiores/dormitorio-principal.jpg")},
                        {img:require("./Assets/images/Slider/unity/interiores/cocina.jpg")},
                        // {img:require("./Assets/images/Slider/unity/interiores/bano-secundario.jpg")},
                        {img:require("./Assets/images/Slider/unity/interiores/bano-principal.jpg")},
                    ],
                },
                avance:{
                    cantButton:"avance-none",
                    title:"Avance de obra Agosto",
                    desktop:[

                    ]
                },
                 recorrido:{active:"",link:"https://kuula.co/share/collection/7P1yj?fs=1&vr=0&sd=1&thumbs=1&info=1&logo=1"}

            },
            form:[
                {value:1,title:"1 Dormitorio"},
                {value:2,title:"2 Dormitorios"},
                {value:3,title:"3 Dormitorios"}
            ],
            info:{
                title:"UNA VIDA EN EL",subtitle:"LUGAR CORRECTO",
                entrega:'¡En Lanzamiento!',
                entregamovil:'¡En Lanzamiento!',
                pisos:"17",depas:"96",dorms:"1, 2 y 3 Dorms.",ubicacion:"Av. Arenales N° 1109, Cercado de Lima",precio:"S/ 234,000",
                movil:{pisos:"17 pisos y 96 depas ",dorms:"1, 2 y 3 Dormitorios.",precio:"S/ 234,000",ubicacion1:"Av. Arenales N° 1109, ",ubicacion2:"Santa Beatriz."},
                fachada:require("./Assets/images/fachada/unity/fachada-unity.jpg"),
                ingreso:require("./Assets/images/fachada/unity/acercamiento-unity.jpg"),
                parrafo1:'Un proyecto que crece contigo, porque entendemos que hoy por hoy, lo más importante es encontrar un lugar correcto para vivir.',
                parrafo2:'Unity tiene como principal objetivo, pasar en familia aprovechar al máximo el tiempo y sobre todo disfrutar  de la comodidad y libertad que le dan nuestros espacios. Nuestro proyecto cuenta con la mejor ubicación, para ti y para los que te acompañan. Además, contamos con la mejor seguridad para que tu única preocupación sea pasarla bien.',
                parrafoArquitectura1:'En Unity podrás disfrutar de cada espacio junto a tu familia o amigos, porque contamos con áreas que te dan esa libertad que tanto necesitas. ¿Qué esperas para formar parte de Unity?',
                brochure:'/brochure/Brochure-unity-2021.pdf',
                arquitectura:'<span class="bold">ARQUITECTURA </span> Y DISEÑO',
            },
            slideDepa:{
                desktop:require("./Assets/sliderdepa/desktop/unity-desktop.png"),
                movil:require("./Assets/sliderdepa/movil/unity-movil.png"),                
            },
        },
        midway:{
            sperant_id: 21,
            route:"/midway",
            areas:{
                comunes:{
                    desktop:[
                        {img:require("./Assets/images/Slider/midway/comunes/lobby.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/coworking.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/game-room.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/gym.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/FABRE_PERSHING_TERRAZA.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/FABRE_PERSHING_AREA_VERDE.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/FABRE_PERSHING_ZONA_DE_CHILD.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/petshower.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/zona-de-lavado.jpg")},
                    ],
                    movil:[
                        {img:require("./Assets/images/Slider/midway/comunes/lobby.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/coworking.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/game-room.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/gym.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/FABRE_PERSHING_TERRAZA.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/FABRE_PERSHING_AREA_VERDE.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/FABRE_PERSHING_ZONA_DE_CHILD.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/petshower.jpg")},
                        {img:require("./Assets/images/Slider/midway/comunes/zona-de-lavado.jpg")},
                    ],
                },
                interiores:{
                    desktop:[
                        {img:require("./Assets/images/Slider/midway/interiores/FABRE_PERSHING_SALA.jpg")},
                        {img:require("./Assets/images/Slider/midway/interiores/FABRE_PERSHING_DORMITORIO_PRINCIPAL.jpg")},
                        {img:require("./Assets/images/Slider/midway/interiores/FABRE_PERSHING_DORMITORIO_CHILD.jpg")},
                        {img:require("./Assets/images/Slider/midway/interiores/dorm-girl.jpg")},
                        {img:require("./Assets/images/Slider/midway/interiores/FABRE_PERSHING_COCINA.jpg")},
                        {img:require("./Assets/images/Slider/midway/interiores/FABRE_PERSHING_BATH_PRINCIPAL.jpg")},
                        {img:require("./Assets/images/Slider/midway/interiores/FABRE_PERSHING_BATH_SECUNDARIO.jpg")},
                    ],
                    movil:[
                        {img:require("./Assets/images/Slider/midway/interiores/FABRE_PERSHING_SALA.jpg")},
                        {img:require("./Assets/images/Slider/midway/interiores/FABRE_PERSHING_DORMITORIO_PRINCIPAL.jpg")},
                        {img:require("./Assets/images/Slider/midway/interiores/FABRE_PERSHING_DORMITORIO_CHILD.jpg")},
                        {img:require("./Assets/images/Slider/midway/interiores/FABRE_PERSHING_COCINA.jpg")},
                        {img:require("./Assets/images/Slider/midway/interiores/FABRE_PERSHING_BATH_PRINCIPAL.jpg")},
                        {img:require("./Assets/images/Slider/midway/interiores/dorm-girl.jpg")},
                    ],
                },
                avance:{
                    cantButton:"avance-none",
                    title:"Avance de obra Agosto",
                    desktop:[

                    ]
                },
                 recorrido:{active:"",link:"https://kuula.co/share/collection/7Yk8N?fs=1&vr=0&sd=1&thumbs=1&info=1&logo=1"}

            },
            form:[
                {value:1,title:"1 Dormitorio"},
                {value:2,title:"2 Dormitorios"},
                {value:3,title:"3 Dormitorios"}
            ],
            info:{
                title:"TU NUEVO ",subtitle:"HOGAR",
                entrega:'¡En Lanzamiento!',
                entregamovil:'¡En Lanzamiento!',
                pisos:"20",depas:"118",dorms:"1, 2 y 3 Dorms.",ubicacion:"Av. Pershing N° 162, Magdalena del Mar",precio:"S/ 232,000",
                movil:{pisos:"20 pisos y 118 depas ",dorms:"1, 2 y 3 Dormitorios.",precio:"S/ 232,000",ubicacion1:"Av. Pershing N° 162, ",ubicacion2:" Magdalena del mar."},
                fachada:require("./Assets/images/fachada/midway/fachada-midway-dia.png"),
                ingreso:require("./Assets/images/fachada/midway/fachada-midway-noche.png"),
                parrafo1:'Midway es la opción ideal para disfrutar de tu propio espacio y acompañado de tu familia con las excelentes areas sociales.',
                parrafo2:'Además de sus hermosos diseños de interiores pensado en la comidad de su familia, esta ubicado en un lugar céntrico donde podras acceder a parques, restaurantes y universidades.',
                parrafoArquitectura1:'Departamentos para vivir cerca a las cosas que te gustan. Cafés, restaurantes, supermercados, parques, etc.',
                brochure:'/brochure/Brochure-midway.pdf',
                arquitectura:'<span class="bold">ARQUITECTURA </span> Y DISEÑO',
            },
            slideDepa:{
                desktop:require("./Assets/sliderdepa/desktop/midway.png"),
                movil:require("./Assets/sliderdepa/movil/midway.png"),                
            },
        }
    }
    