import React, { Component } from 'react'
import "./contacto.scss"
import { FormContainer, setInputProps, checkableBoolProps } from '../common/forms/Form'
import { PostSaleValidatonSchema } from '../common/forms/constraints/ValidatonSchemas'
import Swal from 'sweetalert2'
import $ from "jquery"
import { MailService } from '../common/Http/MailService'
import { postSale } from '../templates/email/PostSale'
import Data from '../data/data'
import axios from 'axios'
import { withRouter } from 'react-router-dom'

import { useParams } from 'react-router';



class Postventa extends Component {
    constructor() {
        super()
        this.mailService = new MailService()
        this.submitForm = this.submitForm.bind(this)
        this.handleFile = this.handleFile.bind(this)
        this.state={
            initialValues:{
                fname: "",
                lname: "",
                email: "",
                telephone: "",
                project: "",
                apartment_number: "",
                message: "",
                terms: false
            },
            file: {}
        }
        
    }
    componentDidMount(){
        $(".link").removeClass("active")
        $("#postventa").addClass("active");
        window.scrollTo(0,0)
        this.setState({initialValues: {
            fname: "",
            lname: "",
            email: "",
            telephone: "",
            project: this.props.id,
            apartment_number: "",
            message: "",
            terms: false
        }})
        let linkid = this.props.id.replace(/[=-]/g, " ")
        document.getElementsByName("project")[0].value = linkid
    }
    submitForm(values, { setSubmitting, resetForm }) {

        const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })

        axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
            "ss_id": "1gE-gi5sEibxdSTN9PqiCYjgZ2SwKJFnhGGQlKldOD34",
            "range": `Postventa!A:XX`,
            "values": [[
                date, values.fname, values.lname, values.email, values.telephone, values.project, values.apartment_number, values.message
            ]],
        }, {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        })

        this.mailService.from("Casaideal <no-reply@casaideal.com.pe>")
            .to("sac@casaideal.com.pe")
            .subject("Postventa")
            .html(postSale(values))
            .attachment(this.state.file)
            .send()
            .then(r => {
                setSubmitting(false)
                this.props.history.push("/gracias/postventa")
                // Swal.fire({
                //     title: '',
                //     text: 'Mensaje enviado',
                //     icon: 'success',
                //     confirmButtonText: 'Aceptar',
                //     confirmButtonColor: 'red'
                // })
            }).catch(e =>{
                setSubmitting(false)
                Swal.fire({
                    title: '',
                    text: '¡Error! Intentelo más tarde',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'red'
                })
            })
    }

    handleFile(e) {
        this.setState({file: e.target.files[0]})
    }

    

    render() {
        return (
            <Data.Consumer>
            {context =>(
            <FormContainer
                initialValues={this.state.initialValues} validationSchema={PostSaleValidatonSchema} onSubmit={this.submitForm}
            >
                {form => {const {handleSubmit } = form;
                return(
                <div className={"vende-tu-terreno "+"proximo"}>
                    <h2 className="title">ATENCIÓN<span className='bold'> AL CLIENTE</span></h2>
                    <div className="flex-column flex-center">
                        <p className="paragraph">Descarga y completa el formato de solicitud postventa</p>
                        <a download href={require("../../Assets/dowloand/formato-solicitud-de-postventa.pdf").default} className="btn download-format">Descargar formato</a>                    
                    </div>
                    <p className="paragraph">Ingresa tus datos, adjunta el formato PDF anterior y un asesor se comunicará contigo</p>
                    <form className="formulario" onSubmit={handleSubmit}>
                        <input type="text" placeholder="Nombre" {...setInputProps("fname", "input col", form)}></input>
                        <input type="text" placeholder="Apellidos" {...setInputProps("lname", "input", form)}></input>
                        <input type="tel" placeholder="Teléfono" {...setInputProps("telephone", "input col", form)}></input>
                        <input type="email" placeholder="E-mail" {...setInputProps("email", "input", form)}></input>
                        
                        <select {...setInputProps("project", "input col", form)} defaultValue={this.state.initialValues.project}>
                            <option disabled selected>Escoge un proyecto</option>
                            <option value="Almudena">Almudena / Jesús María</option>
                            <option value="Allegro">Allegro / San Borja</option>
                            <option value="Alto Mirador">Alto Mirador / Pueblo Libre</option>
                            <option value="Alto Mirador 2">Alto Mirador 2 / Jesús María</option>
                            <option value="Arona">Arona / San Miguel</option>
                            <option value="Bilbao">Bilbao / Magdalena del Mar</option>
                            <option value="Castilla">Castilla / Lince</option>
                            <option value="Los Patriotas">Los Patriotas / San Miguel</option>
                            <option value="Hermano Lobo">Hermano Lobo / San Borja</option>
                            <option value="Midway">Midway / Magdalena</option>
                            <option value="M-Salaverry">MSalaverry / Jesús María</option>
                            <option value="Nou">Nou / Pueblo Libre</option>
                            <option value="Paseo del Parque">Paseo del Parque / Chorrillos</option>
                            <option value="Parque Sol">Parque Sol / Jesús María</option>
                            <option value="Print">Print / Chorrillos</option>
                            <option value="Sevilla">Sevilla / Surco</option>
                            <option value="Town">Town / Cercado de Lima</option>
                            <option value="Trend">Trend / Centro de Lima</option>
                            <option value="Terrazul">Terrazul / Surquillo</option>
                            <option value="Stay">Stay / Jesús María</option>
                            <option value="Unbox">Unbox / Barranco</option>
                            <option value="Unity">Unity / Cercado de Lima</option>
                        </select>
                        <input type="text" placeholder="Nro de departamento" {...setInputProps("apartment_number", "input", form)}></input>
                        <div className="textarea col">
                            <label for="message" className="mensaje">Mensaje*</label>
                            <textarea id="message" rows="5" placeholder="Escribenos tu mensaje aquí." {...setInputProps("message", "form-control", form)}></textarea>
                        </div>
                        <div className="file ">
                            <div className="button-wrap"> 
                                <label className="new-button" for="upload"> Adjuntar archivos</label>
                                <input id="upload" type="file" onChange={this.handleFile}></input>
                                <p id="archivo">{this.state.file.name || "Ningún archivo"}</p>
                            </div>
                            <div className="flex-row atributos">
                                <p id="tipo-archivo">Archivos en: doc, docx, jpg, jpeg, pdf, png. Máximo 6MB</p>
                            </div>
                        </div>
                        <div className="flex-column button-radius">
                            <div className="radio flex-row">
                                <label className="button-title flex-row" for="terminos">
                                <input  type="checkbox" id="terminos" {...checkableBoolProps("terms", "boton-radio", form)}></input>
                                <div className="radius" id="link-terminos"></div> Estoy de acuerdo con las <a href="/politicas-de-privacidad" target="_blank" className="d-block terminos">políticas de privacidad</a></label><br></br>
                            </div>
                        </div>

                        <div className="btn"><button type="submit" className="btn-submit" disabled={form.isSubmitting}>{form.isSubmitting ? "Enviando" : "Enviar"}</button></div>
                    </form>
                </div>)}}
            </FormContainer>
            )}
            </Data.Consumer>
        )
    }
}

const DataPostVenta = withRouter(Postventa);

export default function PostVentaDetails() {
  const { id } = useParams();
  return(
    <DataPostVenta id={id}/>
  )
}
